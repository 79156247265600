import React from 'react';

import { AppBar, Toolbar, Container, IconButton, Typography } from '@material-ui/core';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import './styles.css'


export class PreparationMacOS extends React.Component<{}, {}>{
    public render() {
        return (
<React.Fragment>
<p>(Merci à Luc Pellissier pour l'information)</p>

<p>MacOS fournit par défaut des outils tar, zip... qui ne contiennent pas certaines extensions GNU.
Vous devrez donc installer la version GNU de ces utilitaires, et demander à votre système de les utiliser.</p>

<p>Il vous faudra installer Homebrew. Il s'agit d'un gestionnaire de paquet, la procédure d'installation est détaillée ici :
<a href="https://brew.sh/index_fr">Homebrew</a>.</p>

<p>Une fois l'installation effectuée, exécutez dans un terminal : "brew install gnu-tar".</p>

<p>
Pour utiliser les outils, vous devrez changer votre variable d'environnement "PATH". Pour celà:
<ul>
<li> Ou bien de façon local, vous tapez la commande export PATH="/usr/local/opt/gnu-tar/libexec/gnubin:$PATH" pour que les appels suivants utilisent les outils coreutils,</li>
<li> Ou bien vous ajouter cette commande dans votre fichier .bashrc (qui est appelé à chaque lancement du terminal).</li>
</ul>
</p>

</React.Fragment>
        );

    }
};
