import React, {Fragment, useState} from 'react';

import { AppBar, Toolbar, Container, Typography, Button, IconButton, Drawer, Link, Divider, makeStyles, createStyles, Theme  } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import './pages/general'
import { GeneralPage } from './pages/general';
import { Exo1, Exo2, Exo3 } from './pages/td1';
import { TD2Exo1, TD2Exo2, TD2Exo3 } from './pages/td2';
import { TD3Exo1, TD3Exo2 } from './pages/td3';
import { TD4Exo1 } from './pages/td4';
import { TD5Exo2, TD5Exo1, TD5Exo3 } from './pages/td5';
import { TD6Exo1, TD6Exo2 } from './pages/td6';
import { TD7Exo1, TD7Exo2, TD7Exo3 } from './pages/td7';
import { TD8Exo1 } from './pages/td8';
import { TD9Exo1 } from './pages/td9';
import { TD10Exo0, TD10Exo1, TD10Exo2 } from './pages/td10';
import { TD11Exo1, TD11Exo2, TD11Exo3 } from './pages/td11';
import { TD12Exo1, TD12Exo2, TD12Exo3, TD12Exo4 } from './pages/td12';
import { TD13Exo1 } from './pages/td13';
import { TD14Exo1, TD14Exo2 } from './pages/td14';
import { TD15Exo1, TD15Exo2, TD15Exo3 } from './pages/td15';
import { TD16Exo0, TD16Exo1 } from './pages/td16';
import { ExoProp, TDXPage } from './pages/tdX';
import { PreparationMacOS } from './pages/preparationmacos';
import { PreparationWindows } from './pages/preparationwindows';
import { RendusPage } from './pages/rendus';
import { BrowserRouter as Router, Route, Link as RouterLink } from 'react-router-dom'
import { UtilsPage } from './pages/utils';

const TDList = <Fragment>
  <h3><Link component={RouterLink} to="/td1">TD 1: Bases: Terminal, g++</Link></h3>
                <h3><Link component={RouterLink} to="/td2">TD 2: Fonctions, Recursivité</Link></h3>
                <h3><Link component={RouterLink} to="/td3">TD 3: Pointeurs, Tableaux</Link></h3>
                <h3><Link component={RouterLink} to="/td4">TD 4: Classes, Fichiers multiples, Makefile</Link></h3>
                <h3><Link component={RouterLink} to="/td5">TD 5: Classes, portée des noms, const, Listes doublement chaînées</Link></h3>
                <h3><Link component={RouterLink} to="/td6">TD 6: Références, static, Arithmétique 2048 bits</Link></h3>
                <h3><Link component={RouterLink} to="/td7">TD 7: Allocation dynamique; Construction/Destruction</Link></h3>
                <h3><Link component={RouterLink} to="/td8">TD 8: Allocation dynamique; Matrices.</Link></h3>
                <h3><Link component={RouterLink} to="/td9">TD 9: C++: héritage, RGB</Link></h3>
                <h3><Link component={RouterLink} to="/td10">TD 10: Polymorphisme, Bit hacks.</Link></h3>
                <h3><Link component={RouterLink} to="/td11">TD 11: Fonctions abstraites, exceptions</Link></h3>
                <h3><Link component={RouterLink} to="/td12">TD 12: template{`<>`}</Link></h3>
                <h3><Link component={RouterLink} to="/td13">TD 13: STL containers</Link></h3>
                <h3><Link component={RouterLink} to="/td14">TD 14: Entrées/Sorties</Link></h3>
                <h3><Link component={RouterLink} to="/td15">TD 15: Lambda, Smart pointers, Fabrique, Singleton</Link></h3>
                <h3><Link component={RouterLink} to="/td16">TD 16: Révisions / QCM</Link></h3>
</Fragment>

export class MainView extends React.Component<{}, {drawerOpen: boolean}> {
  constructor(p:{})
  {
    super(p)
    this.state = {drawerOpen:false}
  }

  setDrawerOpen = () => {
    this.setState({drawerOpen:true})
  }

  setDrawerClosed = () => {
    this.setState({drawerOpen:false})
  }  

  public render() {
    const {drawerOpen } = this.state

    const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);
    return (
      <Router>
        <AppBar position="sticky">
          <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.setDrawerOpen}
            edge="start"
            // className={clsx(classes.menuButton, {
            //   [classes.hide]: open,
            // })}
          >
            <MenuIcon />
          </IconButton>
            <Typography>Cours / TDs de C++ - M2 Modélisation Aléatoire</Typography>
            <Button style={{flexGrow:1}} component={RouterLink} to="/">Page principale</Button>
            <Button style={{flexGrow:1}} href="https://www.irif.fr/~carton/Enseignement/C++/">Cours</Button>
            <Button style={{flexGrow:1}} component={RouterLink} to="/utils">Outils</Button>
            <Button style={{flexGrow:1}} component={RouterLink} to="/rendu">Procédure de rendu</Button>
          </Toolbar>
        </AppBar>
        <Container>
        <Drawer variant="temporary" anchor="left" open={drawerOpen} onClose={this.setDrawerClosed}>
          <IconButton onClick={this.setDrawerClosed}>
            <ChevronLeftIcon />
          </IconButton>
        <Divider />
          {TDList}
        </Drawer>
          <Route exact path="/">
            <GeneralPage />
          </Route>
          <Route path="/utils">
            <UtilsPage />
          </Route>          
          <Route path="/td1">
            <TDXPage content={[Exo1, Exo2, Exo3 ] as ExoProp[]} title={<Fragment>TD 1: Bases: Terminal, g++</Fragment>} tdkey="td1" />
          </Route>
          <Route path="/td2">
            <TDXPage content={[TD2Exo1, TD2Exo2, TD2Exo3]} title={<Fragment>TD 2: Fonctions, Recursivité, Pointeurs</Fragment>} tdkey="td2" />
          </Route>
          <Route path="/td3">
            <TDXPage content={[TD3Exo1, TD3Exo2]} title={<Fragment>TD 3: Pointeurs, Tableaux</Fragment>} tdkey="td3" />
          </Route>
          <Route path="/td4">
            <TDXPage content={[TD4Exo1]} title={<Fragment>TD 4: Classes, Organisation du code en plusieurs fichiers</Fragment>} tdkey="td4" />
          </Route>
          <Route path="/td5">
            <TDXPage content={[TD5Exo1, TD5Exo2, TD5Exo3]} title={<Fragment>TD 5 C++: Portée des noms, <span>const</span>, classes et listes doublement chaînées</Fragment>} tdkey="td5" />
          </Route>
          <Route path="/td6">
            <TDXPage content={[TD6Exo1, TD6Exo2]} title={<Fragment>TD 6 C++: références, <samp>static</samp>, Arithmétique 2048 bits</Fragment>} tdkey="td6" />
          </Route>
          <Route path="/td7">
            <TDXPage content={[TD7Exo1, TD7Exo2, TD7Exo3]} title={<Fragment>TD 7 C++: allocation dynamique; construction/destruction.</Fragment>} tdkey="td7" />
          </Route>          
          <Route path="/td8">
            <TDXPage content={[TD8Exo1]} title={<Fragment>TD 8 C++: allocation dynamique; Matrices</Fragment>} tdkey="td8" />
          </Route>
          <Route path="/td9">
            <TDXPage content={[TD9Exo1]} title={<Fragment>TD 9 C++: héritage, RGB</Fragment>} tdkey="td9" />
          </Route>  
          <Route path="/td10">
            <TDXPage content={[TD10Exo0, TD10Exo1, TD10Exo2]} title={<Fragment>TD 10 C++: Polymorphisme, suite et fin.</Fragment>} tdkey="td10" />
          </Route>  
          <Route path="/td11">
            <TDXPage content={[TD11Exo1, TD11Exo2, TD11Exo3]} title={<Fragment>TD 11 C++: Fonctions abstraites, exceptions, template{`<>`}</Fragment>} tdkey="td11"/>
          </Route>  
          <Route path="/td12">
            <TDXPage content={[TD12Exo1, TD12Exo2, TD12Exo3, TD12Exo4]} title={<Fragment>TD 12 C++: <samp>template{`<>`}</samp></Fragment>} tdkey="td12" />
          </Route>  
          <Route path="/td13">
            <TDXPage content={[TD13Exo1]} title={<Fragment>TD 13 C++: STL: string, containers</Fragment>} tdkey="td13" />
          </Route>  
          <Route path="/td14">
            <TDXPage content={[TD14Exo1, TD14Exo2]} title={<Fragment>TD 14 C++: Entrées/Sorties, Smart Pointers</Fragment>}  tdkey="td14"/>
          </Route>  
          <Route path="/td15">
            <TDXPage content={[TD15Exo1, TD15Exo2, TD15Exo3]} title={<Fragment>TD 15 C++</Fragment>} tdkey="td15" />
          </Route>  
          <Route path="/td16">
            <TDXPage content={[TD16Exo0, TD16Exo1]}  title={<Fragment>TD 16 C++: Révisions</Fragment>} tdkey="td16" />
          </Route>
          <Route path="/windows10">
            <PreparationWindows />
          </Route>
          <Route path="/macos">
            <PreparationMacOS />
          </Route>
          <Route path="/rendu">
            <RendusPage />
          </Route>
        </Container>
      </Router>
    );
  }

};