import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'
import {Rendu} from '../rendu'
import { Grid } from '@material-ui/core';


export const TD7Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: <i>Références</i>: suite et fin</Fragment>,
  content: [
    <Fragment><b>Listez</b> dans un fichier <samp>1.1.txt</samp>, par ordre croissant, les <i>indices</i> <samp><b className="red">X</b></samp> des fonctions <samp>Return42_<b className="red">X</b></samp> valides (qui compilent!):
    <br/><br/><img src="td7/1.1.png" alt="Code C++ à interpréter" />
     <br/>
    Le fichier <samp>1.1.txt</samp> devra ressembler contenir une seule ligne qui ressemblera à ça:
    <pre>2, 4, 8, B, D</pre>
    <b>Vérifiez</b> avec le script <a href="1.1.sh" download>1.1.sh</a>: <samp>bash 1.1.sh</samp><br/>
    <Rendu file="1.1.txt"/></Fragment>,
    <Fragment><b>Classez</b> les <samp>struct</samp> suivantes par ordre croissant de leur taille (en octets!). Ce qui correspond à <samp>sizeof(..)</samp>.
      <br/>
  <br/>
  <Grid container justify="center" spacing={5}>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct A {
  int x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct B {
  int* x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct C {
  static int x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct D {
  int& x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct E {
  bool& x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct F {
  bool x;
};`}</SyntaxHighlighter>
    </Grid>
    <Grid item>
    <SyntaxHighlighter language="cpp">{ `struct G {
  int** x;
};`}</SyntaxHighlighter>
    </Grid>
  </Grid>
Si deux <samp>struct</samp> sont de tailles égales, vous les garderez dans l'ordre alphabetique.
  <br/><br/><b className="orange">RENDU:</b> <samp>1.2.txt</samp> qui contiendra une ligne au format: <pre>A, B, C, D, E, F, G</pre>... avec le bon ordre bien sûr.
  <br/>
  <br/>
    </Fragment>
  ]
};


export const TD7Exo2 : ExoProp =
{
  title: <Fragment>Exercice 2: Construction/Destruction</Fragment>,
  content: [
    <Fragment><b>Examinez</b> le code ci-dessous, mis sur 2 colonnes (télécharger: <a href="2.1.cc" download>2.1.cc</a>):
  <Grid container justify="center" spacing={5}>
    <Grid item>
    <SyntaxHighlighter language="cpp">
                            {`#include <iostream>

using namespace std;

class A {
 public:
  A() : x_(0) {
    cout << "Constructing A() @" << this << endl;
  }
  A(int x) {
    x_ = x;
    cout << "Constructing A(" << x << ") @" << this << endl;
  }

  ~A() {
    cout << "~A(): x = " << x_ << ", @" << this << endl;
  }

 private:
  int x_;
};`}</SyntaxHighlighter>
</Grid>
<Grid item>

<SyntaxHighlighter language="cpp">
                            {`int main() {
  cout << "Starting" << endl;
  A a = 123;
  {
    A a;
    A* aa = new A(456);
  }
  for (int i = 0; i < 2; i++) {
    A a(i);
  }
  A* a_ptr[3] = {NULL, NULL, NULL};
  for (int i = 0; i < 3; i++) {
    a_ptr[i] = new A(i);
  }

  for (int i = 0; i < 3; i++) {
    delete a_ptr[i];
  }

  A yop[2];

  // Where is the memory leak ??
}`}</SyntaxHighlighter>
  </Grid>
  </Grid>
    Essayez de <b>simuler</b> son execution vous-même, en notant sur une feuille de papier ce que
    le programme va imprimer.
    {/* <!--
    <br/><br/><b className="orange">Explication</b> au tableau!
    --> */}
    <br/>
    <br/></Fragment>
  ]
};

export const TD7Exo3 : ExoProp =
{
  title: <Fragment>Exercice 3: Tableaux dynamiques</Fragment>,
  content: [
    <Fragment><b>Implémentez</b> dans un fichier <samp>3.1.cc</samp> la classe <samp>Array</samp> déclarée ci-dessous (telécharger <a href="3.1.h" download>3.1.h</a>):
    <SyntaxHighlighter language="cpp">
                              {`// This class represents an array of doubles, with a fixed capacity
// determined at construction.
class Array {
  public:
    // The array will be empty at construction, but all the memory 
    // necessary to store up to "capacity" doubles will already be allocated.
    Array(int capacity);
  
    // It is important to clean up the memory at destruction!
    ~Array();
  
    // Returns the current size (i.e. number of elements) of the array.
    int size() const;
  
    // Returns the *mutable* element at index #i.
    double& operator[](int i);
  
    // Adds an element at the last position of the array.
    void push_back(double x);
  
    // Removes the last element of the array.
    void pop_back();
  
  private:
    int size_;
    double* data_;
};`}</SyntaxHighlighter>
    <b>Testez-la avec l'archive <a href="3.tar.gz" download>3.tar.gz</a></b> et la commande:
    <SyntaxHighlighter language="cpp">{`tar xf 3.tar.gz
  make 3.1`}</SyntaxHighlighter>
  <Rendu file="3.1.cc" /></Fragment>,
    <Fragment>À présent, on va <b>interdire</b> la construction par copie et l'affectation (<i>assignment</i> en anglais):
    <br/>
    <br/>
    Copiez <samp>3.1.h</samp> dans <samp>3.2.h</samp>, de même pour <samp>3.2.cc</samp>, et modifiez-les au besoin.
    <br/>Test: <samp>make 3.2</samp><br/>
    <Rendu file="3.2.h, 3.2.cc" />
    </Fragment>,
    <Fragment>À présent, on va autoriser la construction par copie et l'affectation, et les implémenter correctement.
    <br/><br/>Copiez <samp>3.2.h</samp> dans <samp>3.3.h</samp>, de même pour <samp>3.3.cc</samp>, et modifiez-les au besoin.
     <br/><b>Attention</b>: La <i>capacité</i> du tableau copié doit également être copiée: si je crée un tableau vide de capacité 1000 et que je le copie (ou assigne), sa copie doit être vide, mais avoir une capacité de 1000!
    <br/><br/>Test: <samp>make 3.3</samp><br/>
    <Rendu file="3.3.h, 3.3.cc" /></Fragment>,
    <Fragment>(**) On va à présent faire une <b>véritable</b> allocation dynamique: on allouera la mémoire selon les besoins.
    <br/><b className="red">Attention</b>: on veut garder une complexité <i>amortie</i> en O(1):
      le coût <i>moyen</i> d'une opération doit être O(1),
      et la place memoire utilisé devra être O(N), où N = nombre d'éléments.
    <br/><br/>Copiez <samp>3.3.h</samp> dans <samp>3.4.h</samp>, enlever l'argument <samp>capacity</samp> du constructeur,
    et <b>implémentez</b> <samp>3.4.cc</samp>
    <br/>Test: <samp>make 3.4</samp>
    <br/><Rendu file="3.4.h, 3.4.cc" /></Fragment>
  ]
};
