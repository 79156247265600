import React from 'react';

import { Link, Container } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'

import './styles.css'


export class GeneralPage extends React.Component<{}, {}>{
    public render() {
        return (
            <Container style={{ textAlign: "left" }}>
                <h1>Cours / TDs de C++ - M2 Modélisation Aléatoire</h1>
                <p><i>(Last updated: 2020-09-20)</i></p>

                <h3>Les cours et TDs ont lieu les Mercredi après midi</h3>
                <ul>
                    <li>Cours de 14h à 16h en salle 011</li>
                    <li>TD de 16h15 à 18h15 en salle 2005</li>
                </ul>
       Dans le <a href="https://www.google.com/maps/place/B%C3%A2timent+Sophie+Germain,+Universit%C3%A9+Paris+Diderot/@48.8303181,2.3813476,15z/data=!4m2!3m1!1s0x0:0x92ac28abb0fe2a5a">bâtiment
            Sophie Germain</a>

                <h1>Cours</h1>

                <a href="cours">Page web du Cours</a>

                <h1>TDs</h1>

                <h2>Tous les TDs sont auto-évalués.</h2>
                <b>En général</b>, la note est purement indicative (la note ne compte pas du tout), mais certains TD compteront pour la note de controle continue (vous serez prévenus en avance!)

       Voir la <a href="rendu.html">page de Rendu</a>

                <br />

                <hr />

                <h3>Page des TDs sur <a href="http://moodlesupd.script.univ-paris-diderot.fr/course/view.php?id=3707" title="Moodle">Moodle</a></h3>
                <br />
                <h3><Link component={RouterLink} to="/td1">TD 1: Bases: Terminal, g++</Link></h3>
                <h3><Link component={RouterLink} to="/td2">TD 2: Fonctions, Recursivité</Link></h3>
                <h3><Link component={RouterLink} to="/td3">TD 3: Pointeurs, Tableaux</Link></h3>
                <h3><Link component={RouterLink} to="/td4">TD 4: Classes, Fichiers multiples, Makefile</Link></h3>
                <h3><Link component={RouterLink} to="/td5">TD 5: Classes, portée des noms, const, Listes doublement chaînées</Link></h3>
                <h3><Link component={RouterLink} to="/td6">TD 6: Références, static, Arithmétique 2048 bits</Link></h3>
                <h3><Link component={RouterLink} to="/td7">TD 7: Allocation dynamique; Construction/Destruction</Link></h3>
                <h3><Link component={RouterLink} to="/td8">TD 8: Allocation dynamique; Matrices.</Link></h3>
                <h3><Link component={RouterLink} to="/td9">TD 9: C++: héritage, RGB</Link></h3>
                <h3><Link component={RouterLink} to="/td10">TD 10: Polymorphisme, Bit hacks.</Link></h3>
                <h3><Link component={RouterLink} to="/td11">TD 11: Fonctions abstraites, exceptions</Link></h3>
                <h3><Link component={RouterLink} to="/td12">TD 12: template{`<>`}</Link></h3>
                <h3><Link component={RouterLink} to="/td13">TD 13: STL containers</Link></h3>
                <h3><Link component={RouterLink} to="/td14">TD 14: Entrées/Sorties</Link></h3>
                <h3><Link component={RouterLink} to="/td15">TD 15: Lambda, Smart pointers, Fabrique, Singleton</Link></h3>
                <h3><Link component={RouterLink} to="/td16">TD 16: Révisions / QCM</Link></h3>
            </Container>
        );

    }
};
