import React from 'react';

import { AppBar, Toolbar, Container, IconButton, Typography } from '@material-ui/core';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import './styles.css'


export class PreparationWindows extends React.Component<{}, {}>{
    public render() {
        return (
<React.Fragment>
    
<p>Les outils en ligne de commande usuel de Windows sont malheureusement très différents des outils GNU.
Fort heureusement depuis 2019 Windows peut installer une machine virtuelle légère capable de faire tourner une distribution Linux de manière quasi-transparente.
Cette fonctionnalité est appelée "WSL" pour "Windows Subsystem for Linux". Une seconde version plus performante existe, WSL2.</p> 

<p>Cette fonctionnalité ne permet d'utiliser que les applications en ligne de commande de Linux et non les applications graphiques (A l'heure où ces lignes sont écrites, sept. 2020).</p>

<h1>Prérequis</h1>
Windows 10 version 1903 ou supérieure.

<h1>Procédure d'installation</h1>
<h2>Instructions officielles</h2>
Un pas à pas (en anglais) officiel est disponible à cette addresse (septembre 2020) :
<a href="https://docs.microsoft.com/fr-fr/windows/wsl/install-win10" >https://docs.microsoft.com/fr-fr/windows/wsl/install-win10</a>
<h2>Instructions pas à pas</h2>
<p>
Ces instructions utilisent l'interface graphique plutôt que la ligne de commande mais sont équivalentes.</p>
<p>Il vous faut ajouter les fonctionnalités Windows "Sous Système Windows pour Linux" et "Plateforme d'ordinateur Virtuel".
Pour celà allez dans le menu des fonctionnalités Windows : </p>
<img src="windows10/windowssearch.png" />
<p>Puis sélectionner les features "Sous Système Windows pour Linux" et "Plateforme d'ordinateur Virtuel".:</p>
<img src="windows10/windowsfeatures.png" />
<p>Il vous faudra redémarrer votre machine.</p>

<p>Allez ensuite dans le store Windows 10 et installer l'application "Ubuntu" (il s'agit de la distribution disponible sur les machines en salle de TD).</p>
<img src="windows10/ubuntustore.png" />
<p>Une nouvelle application "Ubuntu" devrait apparaître dans votre menu démarrer. Si vous la lancez, elle vous demandera de créer un mot de passe (indépendant de votre mot de passe Windows).</p>
<img src="windows10/ubuntuinstall.png" />
<p>(image Microsoft).</p>

<h2>Installation de g++</h2>

<p>Vous pourrez alors install g++ via la commande :
    <ul>
        <li>sudo apt install g++</li>
    </ul>
</p>

<h2>Accès à votre système de fichier</h2>

<p>Le système de fichier de WSL est distinct de celui de Windows. Et les fichiers ne sont pas partagés.</p>
<p>Il est cependant possible de copier des fichiers entre le système de fichier WSL et votre partition Windows et réciproquement</p>
<p>En ligne de commande depuis WSL, la partition "C:\" est visible sous le nom "/mnt/c/".
    Typiquement vous accédez à vos téléchargements via "/mnt/c/Users/MonNomUtilisateur/Downloads".</p>
<p>Vous pouvez également utiliser l'explorateur Windows : avec un terminal WSL ouvert,
     le chemin "\\wsl$" vous donnera une vue du système de fichier WSL :</p>
<img src="windows10/wslexplorer.png" />
<p> Le répertoire utilisateur se trouve dans "/home/MonNomUtilisateur" :</p>
<img src="windows10/wslhome.png" />

</React.Fragment>
        );

    }
};
