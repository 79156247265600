import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import { ExoProp } from './tdX';


export const TD5Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Portée des noms; surcharge</Fragment>,
  content: [
  <Fragment><p>
  <b>Simulez l'éxécution</b> de ce programme dans votre tête. <b>Qu'affichera-t'il?</b>
</p>
<img src="td5/1.1.png" width="480" height="640"/>
<br/><br/><b className="orange">RENDU:</b> <code>1.1.txt</code> contenant la réponse (juste un nombre, rien d'autre!)
<br/>
<br/></Fragment>,
  <Fragment>
<b>Que se passera-t'il</b> si dans la classe <b><span>A</span></b> on ajoute une valeur par défaut à la fonction <span>int a(int a)</span>, comme cela:
    <SyntaxHighlighter language="cpp">{`int a(int a = 77) {
  ...
}`}</SyntaxHighlighter>
    <b className="orange">RENDU:</b> <span>1.2.txt</span> contenant la réponse avec une explication brève.
    <br/><br/>
  </Fragment>
  ]
};

export const TD5Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: Déclarations/Définition et <span>const</span></Fragment>,
  content: [
    <Fragment><b>Écrivez</b> un fichier <span>2.1.cc</span> qui <i>implémente</i> la classe
    <span>Pixel</span> dont la déclaration suit: 
    <SyntaxHighlighter language="cpp">{`  
class Pixel {
  public:
    Pixel();
    Pixel(int x, int y, int color);
     
    int GetX();
    int GetY();
    int GetColor();
     
    void SetX(int x);
    void SetY(int y);
     
  private:
    int x_;
    int y_;
    int color_;
};`}
     </SyntaxHighlighter>
    <br/>
    <b>Vérifiez</b> en téléchageant cette <a href="2.tar.gz">archive</a> et en faisant:
    <SyntaxHighlighter language="bash">{`tar xf 2.tar.gz
make 2.1`}</SyntaxHighlighter>
    <b className="orange">RENDU:</b> <span>2.1.cc</span>
    <br/><br/></Fragment>,
    <Fragment>Copiez <span>2.1.h</span> dans <span>2.2.h</span> et modifiez-le <b>uniquement en ajoutant</b> le mot-clé <span>const</span> <b>le plus de fois possible</b>.
    <br/>Écrivez aussi l'implémentation <span>2.2.cc</span> (qui pourra elle aussi partir de <span>2.1.cc</span> comme modèle).
    <br/><b>Vérifiez</b> avec: <span>make 2.2</span>
    <br/><br/><b className="orange">RENDUS:</b> <span>2.2.h</span> et <span>2.2.cc</span>
    <br/><br/></Fragment>,
    <Fragment><b>Faites de même</b> avec les fichiers 2.3.h:
    <SyntaxHighlighter language="cpp">{`
#include "2.2.h"

void PrintPixel(Pixel* pixel);

void ResetPixel(Pixel* pixel);

class PixelArray {
 public:
  PixelArray(int num, Pixel* pixels);

  Pixel* GetPixel(int i);

 private:
  int num_;
  Pixel* pixels_;
};`}

    </SyntaxHighlighter>
    et 2.3.cc:
    <SyntaxHighlighter language="cpp">{`
#include <iostream>

#include "2.3.h"
        
using namespace std;
        
void PrintPixel(Pixel* pixel) {
  cout << "X=" << pixel->GetX() << "; Y=" << pixel->GetY() << endl;
}
        
void ResetPixel(Pixel* pixel) {
  pixel->SetX(0);
  pixel->SetY(0);
}
        
// Compléter l'implémentation de PixelArray ci-dessous.
`}</SyntaxHighlighter>
    <ul>
      <li>Ajoutez tout les <span>const</span> que vous pouvez dans <span>2.3.h</span> (en ne faisant aucune autre modification!)</li>
      <li>Complétez <span>2.3.cc</span></li>
    </ul>
    <b>Vérifiez</b> avec: <span>make 2.3</span>
    <br/><br/><b className="orange">RENDUS:</b> <span>2.3.h</span> et <span>2.3.cc</span>, <b>modifiés</b>.
    <br/><br/></Fragment>
  ]
};


export const TD5Exo3 : ExoProp = {
  title: <Fragment>Exercice 3: Listes doublement chainée</Fragment>,
  content: [
    <Fragment>
      Dans cet exercice on va aborder une structure de données: les <a href="https://en.wikipedia.org/wiki/Doubly_linked_list">listes doublement chainées</a>.
<br/>Le contenu pourrait être arbitraire; ici pour simplifier on prendra un contenu de type <span>double</span>.
<br/>On fera ça a l'aide d'une classe <i>encapsulant</i> une <span>struct</span>:
<ul>
  <li>La class <span>List</span> aura les méthodes:
    <ul>
      <li><span>NumElements()</span> renvoyant le nombre d'éléments</li>
      <li>
        <span>Head()</span> renvoyant un <b>pointeur</b> vers le premier élément
        de la liste (de type <span>Element</span>, cf plus bas), ou <span>nullptr</span>
        si la liste est vide.
      </li>
      <li><span>void Remove(Element* element)</span> qui supprime un élément de la liste.
      </li>
      <li>
        <span>Element* InsertNewElementAfter(double value, Element* position)</span> qui crée un
        nouvel élément de la valeur donnée, et l'ajoute après un élément existant (<span>position</span>)
        de la liste, ou au début de la liste si <span>position = nullptr</span>.
        Elle renverra un pointeur vers l'élément crée.
      </li>
    </ul>
  </li>
  <li>La <i>structure</i> (<span>struct</span>) <span>Element</span> sera définie <b>a l'intérieur</b> de <span>List</span> et aura les champs:
    <ul>
      <li><span>value</span> contenant le <span>double</span> stocké dans cet élément.</li>
      <li><span>prev</span> contenant un pointeur vers l'élément précédent, ou <span>nullptr</span>
      s'il n'y en a pas (i.e. si cet élément est le premier de la liste)</li>
      <li><span>next</span> contenant un pointeur vers l'élément suvant,  ou <span>nullptr</span>
      s'il n'y en a pas (i.e. si cet élément est le dernier de la liste)</li>
    </ul>
  </li>
</ul>
<b>Toutes les opérations ci-dessus doivent fonctionner en <span className="red">temps constant!</span></b>
    </Fragment>,
    <Fragment>
<b>Écrivez</b> le prototype dans un fichier <span>3.1.h</span>
    et l'implémentation dans un fichier <span>3.1.cc</span>
    <b>Vérifiez</b> en téléchargeant <a href="3.tar.gz">cette archive</a> puis:
    <SyntaxHighlighter language="bash">{`tar xf 3.tar.gz
make 3.1`}</SyntaxHighlighter>
    <b className="orange">RENDUS:</b> <span>3.1.h</span> et <span>3.1.cc</span> (Note: un rendu <i>partiel</i> pourra être pris en compte
        mais vous devrez quand même définir <b>toutes</b> les méthodes, en les faisant renvoyer <span>nullptr</span> ou autre valeur
        "défaut" si besoin)
    <br/><br/>
    </Fragment>,
    <Fragment><b>Ajoutez</b> la fonction <span>Find(double d)</span> (dans la classe <span>List</span>) qui renvoie
    un pointeur vers le premier élément de la liste dont la valeur est égale à <span>d</span>,
    ou <span>nullptr</span> si aucun élément n'est égal à <span>d</span>.
    <br/>
    <br/><b>Vérifiez</b> avec: <span>make 3.2</span>
   <br/><br/><b className="orange">RENDUS:</b> <span>3.1.h</span> et <span>3.1.cc</span>, avec la fonction en plus.
   <br/>
   <br/></Fragment>,
   <Fragment><b>(*) Ajoutez</b> une fonction <span>void List::InsertListAfter(const List* other, Element* position)</span>
   qui insère tout le contenu d'une autre liste après un élément donné de la liste courante (ou <span>nullptr</span> pour l'insérer au début).
   <br/>Par exemple, si on insère (1,2,3) après l'élément "4" de (3,4,5) on obtiendra (3,4,1,2,3,5).
   <br/>
   <br/>Comment faire pour que cette fonction marche en <b className="red">temps constant</b>?
   <br/>Indice: n'hésitez pas à ajouter des champs dans votre class <span>Liste</span>.
   <br/><br/><b>Vérifiez</b> avec: <span>make 3.3</span>
   <br/><br/><b className="orange">RENDUS:</b> <span>3.1.h</span> et <span>3.1.cc</span>, avec la fonction en plus.
   <br/>
   <br/></Fragment>
  ]
};
