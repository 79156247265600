import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD10Exo0 : ExoProp = {
  title: <Fragment>Exercice 0: Poymorphisme, encore un peu</Fragment>,
  content: [
    <Fragment>Note: j'ai beaucoup aimé la <a href="http://www.cplusplus.com/doc/tutorial/polymorphism/">page sur le polymorphisme</a> de cplusplus.com.
    à lire si vous êtes perdu. Mais attention, l'exo 1 ci-dessous est encore plus tordu! Bonne chance.
    <b>Entrez</b> dans un fichier <samp>0.1.txt</samp> ce que le code ci-dessous (sur 2 colonnes)
    affiche quand il est exécuté.
    <br/>
    <br/>
    <table summary="screenshot d'un code à examiner">
      <tr>
        <td style={{verticalAlign:"top"}}><img height="520" src="td10/0.1.1.png" alt="screenshot d'un code à examiner: colonne de gauche"/></td>
        <td width="60"/>
        <td style={{verticalAlign:"top"}}><img height="608" src="td10/0.1.2.png" alt="screenshot d'un code à examiner: colonne de droite"/></td>
      </tr>
    </table>
    Exemple de contenu de 0.1.txt:
    <pre>ABCDEF
G
HI
JKL
MNOP
QRSTU</pre>
    <b>Vérifiez</b> avec le script <a href="0.1.sh" download>0.1.sh</a>:
    <pre>bash 0.1.sh</pre>
    <b className="orange">RENDU:</b> <samp>0.1.txt</samp>
    <br/><br/>    
    </Fragment>,
    <Fragment>Que se passe-t'il si on ajoute la ligne suivante a la fin de <samp>main()</samp>?
    <pre><code className="c++">((B*)(&amp;a))-&gt;X();</code></pre>
    <b className="orange">RENDU:</b> <samp>0.2.txt</samp> avec une description très très succinte de ce qui se passe, et une mini explication.
    <br/><br/></Fragment>
  ]
};

export const TD10Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Fonctions abstraites</Fragment>,
  content: [
    <Fragment><h3 className="red">Faites le 2 avant le 1!</h3>
    Téléchargez <a href="1.1.h" download>1.1.h</a> (copié ci-dessous) et <b>complétez</b>-le,
    et <b>implémentez</b> les classes dans un fichier <samp>1.1.cc</samp>.
    <SyntaxHighlighter language="cpp">{`class Sequence {
 public:
  // The value of the sequence at the current step.
  double Value();

  // Advances to the next step.
  void Next();
};

// 1, 1, 2, 3, 5, 8, ...
class FibonacciSequence : public Sequence {
};

// 1, 1, 2, 6, 24, 120, 720, ... modulo x: if x = 10, that's
// 1, 1, 2, 6, 4, 0, 0, ...
class FactorialModXSequence : public Sequence {
 public:
  explicit FactorialModXSequence(int x);
};`}</SyntaxHighlighter>
    Veillez à rendre les fonctions de la classe de base <samp>Sequence</samp>
    <b>abstraites</b>.
    <br/><br/><b>Testez</b> votre code avec
    <a href="1.tar.gz" download>1.tar.gz</a>:
    <pre>
tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU</b>: <samp>1.1.h</samp> et <samp>1.1.cc</samp>
    <br/><br/>
    </Fragment>,
    <Fragment>Copiez <samp>1.1.h</samp> et <samp>1.1.cc</samp> dans <samp>1.2.h</samp> et <samp>1.2.cc</samp>.
    <br/>Ensuite, <b>Créez</b> une classe <samp>ShiftSequence</samp>, sous-classe de <samp>Sequence</samp>,
    dont le constructeur prendra
    en argument une <samp>Sequence*</samp> arbitraire <samp>seq</samp> et un décalage (entier positif)
    <samp>shift</samp>, telle que:
    <br/><i>ShiftSequence(seq, shift)<sub>n</sub></i> =
    <i>seq<sub>seq<sub>0</sub> + n + shift</sub></i>, où <i>seq<sub>0</sub></i>
    est l'étape initiale de <i>seq</i> quand on a construit l'objet
    <samp>ShiftSequence</samp>.
    <br/><br/>Le passage de <samp>seq</samp> se fera par pointeur, et la classe
    <samp>ShiftSequence</samp> <b>prendra possession</b> de l'objet <samp>seq</samp>
    donné: c'est lui qui devra se charger de sa suppression.
    <br/><br/><b>Subtilité supplémentaire</b>: contrairement au reste des <samp>Sequence</samp>
    donnés jusqu'à présent, vos objets <samp>ShiftSequence</samp> ne peuvent pas
    être copiés "naturellement" sans créer des problèmes.
    Empêchez la copie d'un <samp>ShiftSequence</samp> vers un autre! Ma solution
    préférée est la <a
       href="http://ariya.ofilabs.com/2015/01/c-class-and-preventing-object-copy.html">3<sup>ème</sup>
       méthode citée ici</a>.
    <br/><br/><b>Testez</b> votre code avec <pre>make 1.2</pre>
    <b className="orange">RENDU</b>: <samp>1.2.h</samp> et <samp>1.2.cc</samp>
    <br/><br/>
    </Fragment>,
    <Fragment>Copiez <samp>1.2.h</samp> et <samp>1.2.cc</samp> dans <samp>1.3.h</samp> et <samp>1.3.cc</samp>.
    <br/>Ensuite, ajoutez une fonction <samp>int Step()</samp> qui renvoie l'étape courante d'une
    <samp>Sequence</samp> (elle renverra <samp>0</samp> au début).
    Pour ne pas avoir à la redéfinir pour chaque sous-classe de
    <samp>Sequence</samp>, on va utiliser une astuce:
    <ul>
      <li>Renommez tous vos <samp>Next()</samp> en <samp>InternalNext()</samp>.</li>
      <li>Implémentez <samp>Next()</samp> dans la classe de base <samp>Séquence</samp>, en
        utilisant <samp>InternalNext</samp> et un compteur local.</li>
      <li>Selon votre implémentation de <samp>ShiftSequence</samp>, vous aurez
        peut-etre besoin de modifier le compteur local lors de la construction,
        afin qu'il vale bien 0. Ça ne devrait pas vous poser de problème.</li>
    </ul>
    <br/><b>Testez</b> votre code avec <pre>make 1.3</pre>
    <b className="orange">RENDU</b>: <samp>1.3.h</samp> et <samp>1.3.cc</samp></Fragment>
  ]
};

export const TD10Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: Un exemple <em>acceptable</em> de polymorphisme</Fragment>,
  content: [
    <Fragment>Teléchargez l'archive <a href="2.tar.gz" download>2.tar.gz</a> et extrayez son contenu:
    <pre>tar xf 2.tar.gz</pre>
    Télechargez et <b>complétez</b> les fichier <a href="2.1.h" download><samp>2.1.h</samp></a> et <a href="2.1.cc" download><samp>2.1.cc</samp></a>.
    <br/><b>Testez</b> votre code avec: <pre>make 2.1</pre>
    <b className="red">Indice important</b>: vous aurez sans doute une erreur parlant de <em><samp>non-virtual destructor</samp></em>.
    Pour la réparer, vous devrez retoucher <samp>2.1.h</samp> mais attention vous n'avez que le droit d'y <b>ajouter une seule ligne</b>!
    <br/><br/><b className="orange">RENDU:</b> <samp>2.1.h</samp> et <samp>2.1.cc</samp>
    <br/><br/></Fragment>,
    <Fragment><b>Copiez</b> <samp>2.1.h</samp> et <samp>2.1.cc</samp> dans des fichiers
    <samp>2.2.h</samp> et <samp>2.2.cc</samp>.
    <br/><b>Ajoutez</b> une classe <samp>Circle</samp>, dont le <em>nom</em> (donné par <samp>Name()</samp>) sera <samp>Circle</samp>,
    en suivant le même principe que <samp>StraightSquare</samp>.
    <br/>Pour construire un cercle, on donne typiquement les coordonées (x, y) de son centre, et son rayon.
    <br/><br/><b>Testez</b> votre code avec: <pre>make 2.2</pre>
    <b className="orange">RENDU:</b> <samp>2.2.h</samp> et <samp>2.2.cc</samp>
    <br/><br/></Fragment>,
    <Fragment><b>Copiez</b> <samp>2.2.h</samp> et <samp>2.2.cc</samp> dans des fichiers
    <samp>2.3.h</samp> et <samp>2.3.cc</samp>.
    <br/><b>Ajoutez</b> la fonction polymorphique <samp>Perimeter()</samp> à la classe <samp>Shape</samp>. Elle devra renvoyer 0 pour la classe de base.
    Bien sûr, vous devrez l'implementer dans les classes dérivées.
    <br/><b>Ajoutez</b> la chaine: <samp>, perimeter=&lt;perimeter&gt;"</samp> à la fonction <samp>Description()</samp> (attention à la virgule avant <samp>perimeter=</samp>!).
    <br/><br/><b>Testez</b> votre code avec: <pre>make 2.3</pre>
    <b className="orange">RENDU:</b> <samp>2.3.h</samp> et <samp>2.3.cc</samp></Fragment>
  ]
};