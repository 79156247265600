import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'

export const TD9Exo1 : ExoProp = {
    title: <Fragment>Exercice 1: héritage</Fragment>,
    content: [
        <Fragment><b>Implémentez</b> dans un fichier <samp>1.1.cc</samp> la classe <samp>Pixel</samp>
        déclarée ci-dessous (télécharger: <a href="1.1.h" download>1.1.h</a>, ne le modifiez pas!):
        <SyntaxHighlighter language="cpp">{`class Pixel {
     public:
      Pixel(int x, int y);
    
      int X() const;
      int Y() const;
    
      // Prints "INVALID" if the pixel is not valid, otherwise prints "(x, y)".
      void DebugPrint() const;
    
     protected:
      // A valid pixel is a pixel with non-negative coordinates.
      bool IsValid() const;
    
     private:
      const int x_;
      const int y_;
    };`}</SyntaxHighlighter>
                            <b>Testez</b> votre code en téléchargeant <a href="1.tar.gz" >1.tar.gz</a> et en l'exécutant avec:
        <pre>tar xf 1.tar.gz
    make 1.1</pre>
                            <b className="orange">RENDU:</b> <samp>1.1.cc</samp>
                            <br /><br /></Fragment>,
        <Fragment><b>Copiez</b> <samp>1.1.h</samp> et <samp>1.1.cc</samp> dans des fichiers
        <samp>1.2.h</samp> et <samp>1.2.cc</samp>.
        <br /><b>Ajoutez</b> en bas de <samp>1.2.h</samp> le code suivant:
        <SyntaxHighlighter language="cpp">{`class ColoredPixel : public Pixel {
     public:
      ColoredPixel(int x, int y, int color_rgb);
    
      int ColorRgb() const;
      
      // Like before, but we also print the color like this:
      // (4, 3, 0xff0080) -> "(4, 3, R255:G0:B128)"
      void DebugPrint() const;
    
      // We inherit Pixel::IsValid() and don't change it. We don't do more checks.
    
     private:
      const int color_rgb_;
    };`}</SyntaxHighlighter>
    <b>Implémentez</b> cette classe en bas de <samp>1.2.cc</samp>
                        <br /><br /><b>Indications</b>
                        <ul>
                            <li>
                                Le terme <samp>0xff0080</samp> utilisée dans mon exemple correspond à
        l'<a href="https://fr.wikipedia.org/wiki/Syst%C3%A8me_hexad%C3%A9cimal">écriture hexadécimale</a>,
        en base 16, qui est très facile à traduire en binaire:
        <br />
                                <samp>0x0</samp> = 0000b,
        <samp>0x1</samp> = 0001b,
        <samp>0x2</samp> = 0010b,
        <samp>0x3</samp> = 0011b,
        <samp>0x4</samp> = 0100b,
        <samp>0x5</samp> = 0101b,
        <samp>0x6</samp> = 0110b,
        <samp>0x7</samp> = 0111b,
        <samp>0x8</samp> = 1000b,
        <samp>0x9</samp> = 1001b,
        <samp>0xA</samp> = 1010b,
        <samp>0xB</samp> = 1011b,
        <samp>0xC</samp> = 1100b,
        <samp>0xD</samp> = 1101b,
        <samp>0xE</samp> = 1110b,
        <samp>0xF</samp> = 1111b,
        <br />Et du coup: <samp>0x3e5</samp> = 0011 1110 0101 = 001111100101 en binaire.
      </li>
                            <li>
                                La décomposition RGB d'un entier est un grand classique en imagerie:
        <br />Si on regarde les bits d'un entier qui représente une couleur RGB, ses 24 derniers bits sont
        <samp>rrrrrrrrggggggggbbbbbbbb</samp> :
        <ul>
                                    <li>La composante R (Red) correspond aux bits 16..23</li>
                                    <li>La composante G (Green) correspond aux bits 8..15</li>
                                    <li>La composante B (Blue) correspond aux bits 0..7</li>
                                </ul>
                            </li>
                            <li>
                                Pour extraire un sous-bloc des bits d'un entier, on peut utiliser les opérations <samp>{`>>`}</samp> et <samp>&</samp> :
        <ul>
                                    <li><samp>{`>>`}</samp>: décalage à droite. <samp>x {`>>`} 7 == x * 2<sup>7</sup></samp></li>
                                    <li><samp>&</samp>: le ET bit-à-bit (à différencier du ET booléen <samp>&&</samp> !!): <samp>x & 3 ==</samp> les deux derniers bits de x (car 3 = 11 en binaire).
          </li>
                                </ul>
                                <b>Par exemple</b>, pour extraire les bits 11..15 d'un entier x on peut faire:
        <samp>{`(x >> 11) &`} 0x1F</samp> car <samp>0x1F == 11111</samp> en binaire (== 31 en décimal).
      </li>
                        </ul>
                        <br /><b>Testez</b> votre code avec:
    <pre>make 1.2</pre>
                        <b className="orange">RENDU:</b> <samp>1.2.cc</samp>
                        <br /><br />
    </Fragment>,
    <Fragment>Regardez le code suivant (télécharger: <a href="1.3.test.cc" download>1.3.test.cc</a>):
    <SyntaxHighlighter language="cpp">{`#include "1.2.h"

void ApplyDebugPrintTo(const Pixel* p) {
  p->DebugPrint();
}

int main() {
  ColoredPixel colored_pix(1, 2, 0x334455);

  ApplyDebugPrintTo(&colored_pix);

  colored_pix.DebugPrint();

  ((const Pixel*)(&colored_pix))->DebugPrint();

  Pixel p = colored_pix;
  p.DebugPrint();
}`}</SyntaxHighlighter>
                        <b>Faites-le tourner:</b> <pre>make 1.3.test
./1.3.test</pre>
                        <b>Modifiez</b> <samp>1.2.h</samp> <b className="red">sans toucher</b> à <samp>1.2.cc</samp> pour que les 3 premières lignes soient comme la deuxième.
    <br /><b>Vérifiez</b> que le 1.2 marche encore: <pre>make 1.2</pre>
                        <b className="orange">RENDU:</b> <samp>1.2.h</samp> modifié
    <br /><br /></Fragment>,
        <Fragment><b>Copiez</b> <samp>1.2.h</samp> et <samp>1.2.cc</samp> dans <samp>1.4.h</samp> et <samp>1.4.cc</samp>,
        et ré-implémentez la classe <samp>ColoredPixel</samp> en utilisant la <i>composition</i>:
        on aura un membre privé <samp>Pixel pixel_;</samp> au lieu d'hériter de la classe <samp>Pixel</samp>
                            <br /><br /><b>Vérifiez</b> tout seuls, comme des grands:
        <ul>
                                <li>En copiant <samp>1.2.test.cc</samp> dans <samp>1.4.test.cc</samp>
                                    <ul><li>Et en y <b>changeant rien</b> à part le: <samp>#include "1.<span className="red">2</span>.h"</samp></li></ul>
                                </li>
                                <li>En copiant <samp>1.2.test.sh</samp> dans <samp>1.4.test.sh</samp>
                                    <ul>
                                        <li>Et en y <b>changeant rien</b> à part la référence à <samp>1.<span className="red">2</span>.test</samp></li>
                                        <li>Et en n'oubliant pas de le rendre éxécutable (<samp>chmod +x 1.4.test.sh</samp>)</li>
                                    </ul>
                                </li>
                                <li>Et en ajoutant des entrées pour le <samp>1.4</samp> dans le <samp>Makefile</samp>.</li>
                                <li>La commande suivante doit marcher et faire tourner votre code:
            <pre>make 1.4</pre>
                                </li>
                            </ul>
                            <b className="orange">RENDU:</b> <samp>1.4.h</samp>, <samp>1.4.cc</samp>, <samp>1.4.test.cc</samp>, et le <samp>Makefile</samp> modifié.
        <br /><br /></Fragment>
    ]
};


export const TD9Exo2 : ExoProp = {
    title: <Fragment>Exercice 2: <samp>public</samp>, <samp>private</samp>, <samp>protected</samp></Fragment>,
    content: [
        <Fragment><b>Entrez</b> dans un fichier <samp>2.1.txt</samp> la liste des fonctions F<b className="pink">X</b>
        qui ne compilent pas dans le code ci-dessous (sur 2 colonnes),
        en tant que simples nombres séparés par des virgules.
        <br />
                            <br />
                            <table summary="screenshot d'un code à examiner">
                                <tr>
                                    <td style={{ verticalAlign: "top" }}><img height="360" src="td9/2.1.1.png" alt="screenshot d'un code à examiner: colonne de gauche" /></td>
                                    <td style={{ verticalAlign: "top" }}><img height="540" src="td9/2.1.2.png" alt="screenshot d'un code à examiner: colonne de droite" /></td>
                                </tr>
                            </table>
        Exemple de contenu de 2.1.txt:
        <pre>1,2,3</pre>
                            <b>Vérifiez</b> avec le script <a href="td9/2.1.sh" download>2.1.sh</a>:
        <pre>bash 2.1.sh</pre>
                            <b className="orange">RENDU:</b> <samp>2.1.txt</samp>
                            <br /><br /></Fragment>,
        <Fragment>Idem ici. Les nombres que vous entrez dans <samp>2.2.txt</samp> doivent
        correspondre aux commentaires à droite des lignes.
        <table summary="screenshot d'un code à examiner">
                                <tr>
                                    <td style={{ verticalAlign: "top" }}><img height="340" src="td9/2.2.1.png" alt="screenshot d'un code à examiner: colonne de gauche" /></td>
                                    <td style={{ verticalAlign: "top" }}><img height="400" src="td9/2.2.2.png" alt="screenshot d'un code à examiner: colonne de droite" /></td>
                                </tr>
                            </table>
                            <b>Vérifiez</b> avec le script <a href="td9/2.2.sh" download>2.2.sh</a>:
        <pre>bash 2.2.sh</pre>
                            <b className="orange">RENDU:</b> <samp>2.2.txt</samp>
                            <br /><br />
        </Fragment>
    ]
};