import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD8Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Matrices</Fragment>,
  content: [
    <Fragment><b>Implémentez</b> dans un fichier <samp>1.1.cc</samp> la classe <samp>Matrix</samp> déclarée ci-dessous (télécharger: <a href="1.1.h" download>1.1.h</a>, ne le modifiez pas!):
    <SyntaxHighlighter language="cpp">{ `class Matrix {
public:
Matrix(int num_rows, int num_columns);
~Matrix();

int NumRows() const;
int NumColumns() const;

double& Element(int row, int col);

private:
int num_rows_;
int num_columns_;
// We'll use the "flat" representation.
double* elements_;
};`}</SyntaxHighlighter>
  <b>Indices</b>:
  <ul>
    <li>On utilise la représentation <i>plate</i></li>
    <li><samp>Element(i, j)</samp> est l'élement à la rangée <i>i</i> et la colonne <i>j</i>. On indexe a partir de zéro.</li>
    <li>Il faudra bien initialiser les éléments à zéro. Regardez l'<a href="http://stackoverflow.com/questions/7546620/operator-new-initializes-memory-to-zero">excellente réponse sur la question</a> (sur stackoverflow.com)
    </li>
    <li>N'oubliez pas de libérer la mémoire...</li>
  </ul>
  <br/><b>Testez</b> votre code en téléchargeant <a href="1.tar.gz">1.tar.gz</a> et en l'exécutant avec:
  <pre>tar xf 1.tar.gz
make 1.1</pre>
  <b className="orange">RENDU:</b> <samp>1.1.cc</samp>
  <br/><br/></Fragment>,
  <Fragment>Copiez <samp>1.1.h</samp> et <samp>1.1.cc</samp> dans
  <samp>1.2.h</samp> et <samp>1.2.cc</samp> et
  <b>ajoutez</b> une fonction <i>statique</i> <samp>Identity</samp> dans la class <samp>Matrix</samp> pour créer une matrice identité de taille <i>n</i>.
  <br/>N'oubliez pas de changer le <samp>#include "1.1.h"</samp> vers <samp>1.2.h</samp>!
  <br/><b>Testez</b> votre code avec: <samp>make 1.2</samp>
  <br/><br/><b className="orange">RENDUS:</b> <samp>1.2.h</samp>, <samp>1.2.cc</samp>
  <br/><br/></Fragment>,
  <Fragment>Copiez <samp>1.2.h</samp> et <samp>1.2.cc</samp> dans
  <samp>1.3.h</samp> et <samp>1.3.cc</samp>, <b>changez</b> le <samp>#include "1.2.h"</samp> vers <samp>1.3.h</samp>, et essayez:
  <pre>make 1.3.1</pre>
  puis:
  <pre>make 1.3.2</pre>
  Vous devriez avoir des erreurs: ça compile bien, mais ça ne tourne pas!
  <br/><b>Regardez</b> les fichiers tests <samp>1.3.1.test.cc</samp> et <samp>1.3.2.test.cc</samp> (ré-inclus ci-dessous, mais ils doivent déja être dans votre repertoire de travail):
  <table summary="Copie des fichiers 1.3.1.test.cc et 1.3.2.test.cc sur 2 colonnes">
    <tr>
      <td style={{verticalAlign:"text-top"}}>
      <SyntaxHighlighter language="cpp">{ `#include "main_utils.h"
#include "1.3.h"

int main() {
Matrix a = Matrix::Identity(5);
Matrix b = a;
Matrix c = b;
c.Element(2, 1) = 4.5;
CHECK_EQ(c.Element(2, 1), 4.5);
CHECK_EQ(b.Element(2, 1), 0.0);
CHECK_EQ(a.Element(2, 1), 0.0);
cout << "PASSED" << endl;
}`}</SyntaxHighlighter>
      </td>
      <td className="bigsep"/>
      <td style={{verticalAlign:"text-top"}}>
      <SyntaxHighlighter language="cpp">{ `#include "main_utils.h"
#include "1.3.h"

int main() {
Matrix a = Matrix::Identity(5);
Matrix b = a;
cout << "PASSED" << endl;
}`}</SyntaxHighlighter>
      </td>
    </tr>
  </table>
  <b>Quel est le problème?</b> (aide au tableau)
  <br/><br/>Pour le réparer, <b>ajoutez</b> un <i>constructeur de copie</i> valable. <b>Vérifiez</b> que <samp>make 1.3.1</samp> et <samp>make 1.3.2</samp> marchent bien desormais.
  <br/>
  <br/><i>NOTE:</i> Normalement on devrait aussi ajouter l'opérateur d'assignment (<samp>operator=</samp>),
  ainsi que le constructeur par transfer (<i>move constructor</i>) et l'opérateur d'assignement par transfer (<i>move assignment operators</i>) en C++11.
  <br/>Pour comprendre quand l' <samp>operator=</samp> est utilisé au lieu du constructeur par copie, voir <a href="https://en.wikipedia.org/wiki/Assignment_operator_(C%2B%2B)">cette page</a> qui l'explique en 2 temps 3 mouvements.

  <br/><br/><b className="red">Vérifiez encore</b> avec <samp>make 1.3.3</samp>.
  <br/><br/><b className="orange">RENDUS:</b> <samp>1.3.h</samp>, <samp>1.3.cc</samp>
  <br/><br/></Fragment>,
  <Fragment>De même, copiez votre code dans <samp>1.4.h</samp> et <samp>1.4.cc</samp>,
  <b>changez</b> le <samp>#include "1.3.h"</samp> vers <samp>1.4.h</samp>, et essayez: <samp>make 1.4</samp>.
  <br/>Essayez de comprendre ce qui cloche en regardant <samp>1.4.test.cc</samp> (également inclus ci-dessous).
  <br/><b>Comment le réparer</b>, <i>sans toucher</i> <samp>1.4.test.cc</samp>? (aide au tableau)
  <SyntaxHighlighter language="cpp">{ `#include "main_utils.h"
#include "1.4.h"

int main() {
Matrix a(4, 3);
a.Element(2, 1) = 42.0;
a.Element(3, 2) = -3.5;
const Matrix b = a;
cout << "b[2][1] = " << b.Element(2, 1) << endl;
cout << "b[3][2] = " << b.Element(3, 2) << endl;
cout << "PASSED" << endl;
}`}</SyntaxHighlighter>
  <b className="orange">RENDUS:</b> <samp>1.4.h</samp>, <samp>1.4.cc</samp>
  <br/><br/></Fragment>,
  <Fragment>On va à présent écrire des petit modules d'opérations sur les matrices.
  <br/>Commençons par l'affichage:
  <br/><b>Écrire</b> des fichiers <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp>
  contenant la déclaration et la définition d'une fonction <samp>void PrintMatrix(const Matrix& m)</samp>, qui affiche une matrice comme ça (même les espaces doivent parfaitement respecter l'exemple!):
  <table summary="matrice affichée">
    <tr>
      <td>
        <pre>[[       1       2     3.4]
[    -2.1 3.14159      -9]]</pre>
      </td>
    </tr>
  </table>
   <b>Indices</b>
   <ul>
     <li>
       On pourra utiliser <samp>printf("% 8g", ...)</samp> pour afficher un <samp>double</samp>
       aligné à droite, en prenant au moins 8 caractères.
       <br/>Voir la <a href="http://fr.cppreference.com/w/cpp/io/c/fprintf">documentation</a>. Il faut inclure <samp>{`<cstdio>`}</samp>.
     </li>
     <li>
       Pensez à inclure <samp>1.4.h</samp>
       <br/>Vous aurez sans doute un souci de double inclusion de <samp>"1.4.h"</samp>.
       C'est un grand classique des projets C++ avec plusieurs fichiers!
       <br/>La parade est d'insérer le code suivant <i>autour</i> du contenu de <samp>1.4.h</samp>:
       <pre>#ifndef _1_4_H
#define _1_4_H
...
...
#endif  // _1_4_H</pre>
     </li>
   </ul>
  <b>Vérifiez</b>: <samp>make 1.5</samp>
  <br/><br/><b className="orange">RENDUS:</b> <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp>.
  <br/><br/></Fragment>,
  <Fragment>
  On va maintenant écrire le <a href="https://fr.wikipedia.org/wiki/Produit_matriciel">produit matriciel</a>.
  <br/>Toujours dans les même fichiers <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp>, ajoutez
  une fonction <samp>Matrix MatrixProd(const Matrix& a, const Matrix& b)</samp> qui calcule <i>a * b</i>
  et le renvoie dans une nouvelle matrice.
  Vous pourrez supposer que les dimensions de <i>a</i> et <i>b</i> collent bien.
  <br/>
  <br/>
  <b>Verifiez</b> vous-même, avec votre propre fichier <samp>1.6.test.cc</samp>! Vous pouvez par exemple:
  <ul>
    <li>Vous inspirer de <samp>1.2.test.cc</samp> pour l'ossature (notamment le <samp>#include "main_utils.h"</samp> qui vous donne <samp>CHECK_EQ(..)</samp>), et de <samp>1.5.test.cc</samp> pour l'entrée manuelle d'une matrice.</li>
    <li>Remplir une petite matrice avec des nombres au hasard, et vérifier que son produit avec l'identité, à droite ou à gauche, est égal à la matrice de départ</li>
    <li>Essayer une petite multiplication, comme celle donnée en exemple sur la page wikipédia.</li>
  </ul>
  Faites en sorte que 1.6.test.cc puisse tourner avec:
  <pre><code>g++ -std=c++11 1.4.cc matrix_ops.cc 1.6.test.cc && ./a.out</code></pre>

  <b className="orange">RENDUS:</b> <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp> mis à jour, et <samp>1.6.test.cc</samp>
  <br/><br/></Fragment>,
  <Fragment>Pour finir, on va écrire l'<a href="https://fr.wikipedia.org/wiki/Exponentiation_rapide">exponentiation rapide</a> pour calculer la puissance <i>n</i>-ième (n entier positif ou nul) d'une matrice.
  <br/>
  <br/><b>Ajoutez</b> à <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp> une fonction
  <samp>Matrix MatrixPower(const Matrix& m, int p)</samp> qui, pour une matrice carrée <i>m</i>
  et un exposant entier <i>p</i>, calcule <i>m<sup>p</sup></i>.
  <br/><br/>Indices <b className="red">importants</b>:
 <ul>
    <li>Il est <b>dangereux</b> de manipuler des classes dont un des trois (destructeur, constructeur par copie, operateur d'assignement <samp>=</samp>) a été "personalisé" sans que les 3 soient personalisés.
      <br/>C'est pourtant ce qu'on a fait jusqu'ici! Car on a pas encore personalisé l'opérateur d'assignement <b><samp>operator=</samp></b>.
      <br/>Par exemple, le code suivant est problématique, au niveau de la
      2<sup>ème</sup> ligne:
      <SyntaxHighlighter language="cpp">{ `Matrix x = quelquechose;
x = MatrixProd(x, x);`}</SyntaxHighlighter>
      Pour réparer cette erreur, vous pouvez personaliser  l'<samp>operator=</samp> vous-même, par exemple. Vous pouvez aussi choisir de complètement éviter l'opérateur<samp>=</samp>, en s'appuyant par exemple sur une fonction
      <samp>void CopyTo(const Matrix& from, Matrix* to)</samp> qui copie
      <samp>from</samp> dans <samp>to</samp>, en supposant qu'elles ont déja les mêmes
      dimensions; fonction que vous ajouteriez vous-même a <samp>matrix_ops.h</samp>. 
    </li>
    <li><b className="red">Attention</b> aussi à l'overflow avec le dernier bit de la puissance entière!</li>
  </ul>
  <br/><b>Vérifiez</b>: <samp>make 1.7</samp>
  <br/><br/><b className="orange">RENDUS:</b> <samp>matrix_ops.h</samp> et <samp>matrix_ops.cc</samp> mis à jour.
  <br/><br/><b className="orange">BONUS:</b> <samp>1.7.txt</samp> qui donne la complexité (en fonction de <i>p</i> et de la taille <i>n</i> de la matrice).
  <br/><br/> </Fragment>
  ]
};