import React from 'react';


import { Card, CardContent, Typography, CardHeader, Tabs, Tab } from '@material-ui/core';
import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'


export interface ExoProp
{
    title: JSX.Element
    content: JSX.Element[]
}

interface TDXProp {tdkey:string, title:JSX.Element, content: ExoProp[]};


export class TDXPage extends React.Component <TDXProp, {tabValue: number}>{
    constructor(p:TDXProp)
    {
        super(p)
        this.state = {tabValue:0}
    }

  public render() {
      const testsh = `/${this.props.tdkey}/test.sh`
      const {tabValue} = this.state
    return (
      <React.Fragment>
        <Typography variant="h3">{this.props.title}</Typography>
        <Tabs variant="fullWidth"
          value={tabValue}
          onChange={(e, value) => this.setState({tabValue:value})}
          >
        {this.props.content.map((exo: ExoProp) => {
          return <Tab label={exo.title} />
        })}
      </Tabs>
      <Card>
        <CardContent>
        <ol>
                            {
                                this.props.content[tabValue].content.map((question: JSX.Element) => <React.Fragment><li>{question}</li></React.Fragment>)
                            }
                            </ol>
        </CardContent>
      </Card>
        {/* {
        this.props.content.map((exo: ExoProp) => {
            return (
                <React.Fragment>
                    <Card>
                      <CardHeader title={exo.title} />
                        <CardContent>
                            <ol>
                            {
                                exo.content.map((question: JSX.Element) => <React.Fragment><li>{question}</li></React.Fragment>)
                            }
                            </ol>
                        </CardContent>
                    </Card>

            <br/>
            </React.Fragment>)
        }
        )} */}

        <footer>
          Auto-évaluation: clic droit sur <a href={`${this.props.tdkey}/g.tar.xz`} download>g.tar.xz</a>, "Sauvegardez sous". Puis, dans un terminal:
<SyntaxHighlighter language="bash">{`tar xf g.tar.xz
cd grade
bash grade.sh ../td1.tar`}
          </SyntaxHighlighter>


N'oubliez pas de soumettre votre travail sur <a href="http://moodlesupd.script.univ-paris-diderot.fr/course/view.php?id=3707" title="Moodle">Moodle</a>
après l'avoir testé grâce au script : <a href={testsh} title="Script de test de l'archive de rendu"><samp>test.sh</samp></a>
(voyez <a href="rendu" title="Procédure de rendu">la procédure de rendu</a>).
</footer>
      </React.Fragment>
    );

  }
};
