import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD2Exo1 : ExoProp = {
  title: (<React.Fragment>Exercice 1: Fonctions, <samp>#include</samp>, déclarations</React.Fragment>),
  content: [
  (<React.Fragment><b>Écrire</b> un fichier <samp>1.1.cc</samp> contenant uniquement une fonction <samp>int LaSolution(int x)</samp> qui renvoie toujours x + 42.
    Vérifiez-la avec le fichier <a href="td2/1.1.main.cc"><samp>1.1.main.cc</samp></a> suivant
    <SyntaxHighlighter language="cpp">{`#include <iostream>
using namespace std;

#include "1.1.cc"

int main() {
  cout << LaSolution(3) << endl;
  cout << LaSolution(-1) << endl;
}`}</SyntaxHighlighter>
 donc en faisant: 
    <SyntaxHighlighter language="bash">g++ 1.1.main.cc && ./a.out</SyntaxHighlighter>
    <b className="orange">RENDU:</b> <samp>1.1.cc</samp><br/><br/></React.Fragment>),
  (<React.Fragment>Copiez <samp>1.1.main.cc</samp> dans <samp>1.2.main.cc</samp> puis <b>modifiez</b> ce dernier en enlevant <samp>#include "1.1.cc"</samp>, et tentez de le compiler manuellement avec g++. Comment faire pour que ça marche?
    <br/>Indices:
    <ul>
      <li>g++ peut prendre plusieurs fichiers en arguments</li>
      <li>Ajoutez une <i>déclaration</i> de la fonction <samp>LaSolution</samp>.</li>
    </ul>
    <br/><b className="orange">RENDUS:</b> <samp>1.2.main.cc</samp> et un fichier <samp>1.2.txt</samp> contenant la ligne de commande pour compiler<br/><br/></React.Fragment>)
  ]
};


export const TD2Exo2 : ExoProp = 
{ title: (<React.Fragment>Exercice 2: Récursivité (ou pas), <i>stack overflow</i></React.Fragment>),
  content: [
    (<React.Fragment>On dit qu'une fonction est <i>récursive</i> quand elle s'appelle elle-même.
    <b>Écrire</b> un fichier <samp>2.1.cc</samp> contenant juste une fonction <samp>FactorielleMod</samp> qui prend un entier <i>n</i> et qui calcule <b><i>n!</i> modulo 1000000007</b>.
    <br/>La fonction devra être <b>récursive</b>: sans utiliser de boucle <samp>for</samp>, <samp>while</samp> ou <samp>do</samp>.
    <br/><b className="red">Attention</b> aux <i>overflow</i>: un entier <samp>int</samp> ne va pas au-dessus de 2<sup>31</sup>-1. Un entier <samp>long</samp> va jusqu'à 2<sup>63</sup>-1 par contre.
    <br/>
    <br/><b>Testez</b>-le et corrigez-le avec ce fichier main.cc, comme avant.
    <br/><b>Note</b>: 100<i>! </i> mod 1000000007 = 437918130.
    <SyntaxHighlighter language="cpp">
                            {`#include <iostream>
using namespace std;

#include "2.1.cc"

int main() {
  cout << "Entrez un entier n: ";
  int n;
  cin >> n;
  cout << FactorielleMod(n) << endl;
}`}</SyntaxHighlighter>
    <b className="orange">RENDU:</b> <samp>2.1.cc</samp><br/><br/></React.Fragment>),
  (<React.Fragment>    <b>Essayez</b> avec <i>n=</i>10000000 (10 millions). Que voyez-vous?
  <br/>
  <br/>C'est un <i>stack overflow</i>! Pour s'en sortir, deux solutions:
  <ol type="A">
    <li>Augmenter la taille maximum de la pile (<i>stack</i>). Cf <a href="http://stackoverflow.com/questions/2275550/change-stack-size-for-a-c-application-in-linux-during-compilation-with-gnu-com">aide en ligne</a>:
      <ul>
        <li>Ajouter <samp>#include &lt;sys/resource.h&gt;</samp> dans votre main</li>
        <li>Et au début de la fonction <samp>main()</samp>, ajoutez ces lignes:
        <SyntaxHighlighter language="cpp">
                            {`struct rlimit r;
getrlimit(RLIMIT_STACK, &r);
r.rlim_cur = 100 &lt;&lt; 20;  // 100 MB.
setrlimit(RLIMIT_STACK, &r);`}</SyntaxHighlighter>
        </li>
      </ul>
      <b className="red">C'est à éviter</b> en général, sauf cas <b>très</b> rare.
      <br/><br/>
    </li>
    <li>Transformer votre code récursif en code <i>itératif</i>, qui est bien plus dans l'esprit de C++:
      <br/><br/>
      <b>Recodez</b> une autre version <samp>FactorielleMod2(n)</samp> dans un
      nouveau fichier <samp>2.2.cc</samp>, sans récursion mais avec une boucle 
      <samp>for</samp>.
      Vous pouvez le tester avec le <samp>main</samp> d'avant, sans oublier de
      changer le <samp>2.1.cc</samp> en <samp>2.<b>2</b>.cc</samp> et <samp>FactorielleMod</samp>
      en <samp>FactorielleMod<b>2</b></samp>.
      <br/>
      <br/><b className="orange">RENDU:</b> <samp>2.2.cc</samp><br/>
    </li>
  </ol>
  <br/></React.Fragment>),
  (<React.Fragment><b>Optionnel</b> (non compabilisé, à faire en fin de TD): vous pouvez comparer les vitesses de <samp>FactorielleMod()</samp> et <samp>FactorielleMod2()</samp>, par exemple, en calculant la somme de <i>n!</i> pour <i>n</i> allant de 1 à 10000).
  <br/>Que cela donne-t-il?.
  <br/><br/></React.Fragment>),
  (<React.Fragment><b>Écrivez</b> dans un fichier <samp>2.4.cc</samp> une fonction <b>récursive</b> <samp>FiboRec</samp> qui calcule le <i>n</i>-ième terme de la suite de Fibonacci, donnée par:
    <br/><i>F<sub>n</sub> = F<sub>n-1</sub> + F<sub>n-2</sub></i> et <i>F<sub>0</sub> = F<sub>1</sub> = 1.</i>
    <br/>Essayez de calculer <samp>FiboRec(60)</samp>. Que se passe-t-il? Pourquoi?
    <br/>
    <br/><b className="orange">RENDU:</b> <samp>2.4.cc</samp><br/><br/></React.Fragment>),
  (<React.Fragment><b>Écrivez</b> dans un fichier <samp>2.5.cc</samp> une fonction <samp>FiboIter</samp> qui calcule <i>F<sub>n</sub></i>, mais itérativement cette fois. Changez sa valeur de retour en <samp>double</samp>.
    <br/>Comparez les valeurs de <samp>FiboIter</samp> et <samp>FiboRec</samp> pour de petits nombres, puis essayez de calculer <samp>FiboIter(60)</samp>, puis <samp>FiboIter(1000)</samp>.
    <br/>
    <br/><b className="orange">RENDU:</b> <samp>2.5.cc</samp><br/><br/></React.Fragment>),
  (<React.Fragment><b>Écrivez</b> dans un fichier <samp>2.6.cc</samp> une fonction <b>récursive</b> <samp>Pgcd</samp> qui calcule le plus grand diviseur commun entre deux <samp>long</samp>.
    <br/>
    <br/><b>Vérifiez</b> que <samp>Pgcd(2250, 1000)</samp> = 250; et essayez <samp>Pgcd(129387194869817412L, 19827398170419240L)</samp> et <samp>Pgcd(1000000007, 1)</samp>. Si ca ne marche pas (ou prend trop de temps), essayez un autre algorithme plus efficace!
    <br/>
    <br/><b className="orange">RENDUS:</b> <samp>2.6.cc</samp> et une brève explication (dans un fichier <samp>2.6.txt</samp>) de pourquoi on a <b>pas</b> le
    problème de <i>stack overflow</i> avec cette fonction<br/><br/></React.Fragment>)
  ]
};

export const TD2Exo3 : ExoProp = {
  title : <React.Fragment>Exercice 3: Pointeurs, tableaux, algorithmes</React.Fragment>,
  content: [
    <React.Fragment>Étant donné le fichier <a href="td2/3.1.cc"><samp>3.1.cc</samp></a> suivant:
    <SyntaxHighlighter language="cpp">
                              {`#include <iostream>
  using namespace std;
  
  // Ne touchez pas à cette fonction! Utilisez-là telle qu'elle est.
  void GetDate(int* year, int* month, int* day) {
    *year = 2018;
    *month = 9;
    *day = 19;
  }
  
  int main() {
    // TODO: Utilisez GetDate() pour récuperer la date dans des
    // variables y, m et d de type int.
  
    // Affichage de la date.
    cout << y << "-" << m << "-" << d << endl;
  }`}</SyntaxHighlighter>
      Téléchargez-le et <b>complétez</b>-le.
      <br/><b>Vérifiez</b> en le compilant et en l'éxecutant: il doit marcher, et afficher <samp>2018-09-19</samp>.
      <br/><span className="grey"><b>Rappel</b>: Pour compiler et éxecuter d'une seule traite: </span>
      <SyntaxHighlighter language="bash">g++ votre_code.cc && ./a.out</SyntaxHighlighter>
      <br/>
      <br/><b className="orange">RENDU:</b> <samp>3.1.cc</samp> (complété!)
      <br/><br/></React.Fragment>,
    <React.Fragment><b>Écrivez</b> un fichier <samp>3.2.cc</samp> contenant une fonction <samp>Strip()</samp> qui enlève tous les espaces d'une chaîne de caractères.
    <br/>Indications:
    <ul>
      <li>On utilisera le type <b><samp>char*</samp></b> pour representer une chaîne de caractères.</li>
      <li>Une chaîne de caractères étant un <b>tableau</b>, on peut le modifier <i>en place</i> dans une fonction. Pas besoin de renvoyer une autre chaîne de caractères.</li>
      <li>Le <i>caractère</i> "espace" (de type <samp>char</samp>) s'écrit avec les guillemets simples: <samp>' '</samp></li>
    </ul>
    <br/><b>Vérifiez</b> votre fonction en téléchargeant <a href="td2/3.2.test.cc" download><samp>3.2.test.cc</samp></a> dans le même repertoire que <samp>3.2.cc</samp> et en le compilant/exécutant.
    <br/><br/><b className="orange">RENDU:</b> <samp>3.2.cc</samp>
    <br/><br/></React.Fragment>,
    <React.Fragment><b>Écrivez</b> un fichier <samp>3.3.cc</samp> qui <samp>#include "3.2.cc"</samp> et qui contient une fonction <samp>main()</samp> pour que votre programme execute la fonction <samp>Strip</samp> sur
    son premier argument, et l'affiche (avec un saut de ligne à la fin). Donc, si vous faites ça:
    <SyntaxHighlighter language="bash">{`g++ 3.3.cc
./a.out " Bonjour  madame     la marquise H E L L O    W    ORL D  "`}</SyntaxHighlighter>
    Vous devez obtenir: <samp>BonjourmadamelamarquiseHELLOWORLD</samp>
    <br/><br/>
    Indications:
    <ul>
      <li>Il va falloir utiliser la fonction <samp>main()</samp> standard, et non sa version abrégée comme on a fait jusque-là: <samp>int main(int argc, char** argv)</samp></li>
      <li>Le premier argument de votre programme sera stocké dans <samp>argv[1]</samp>, qui est modifiable.</li>
    </ul>
    <br/><b className="orange">RENDU:</b> <samp>3.3.cc</samp>
    <br/><br/></React.Fragment>
  ]
};

