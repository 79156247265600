import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD15Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Lambda</Fragment>,
  content: [
    <Fragment><b>Implémentez</b> le programme déclaré dans
    <a href="td15/1.1.h" download>1.1.h</a> (copié ci-dessous) dans un fichier
    <samp>1.1.cc</samp>. <b>Ne modifiez pas</b> <samp>1.1.h</samp> !
    <SyntaxHighlighter language="cpp">{`#include &lt;functional&gt;
#include &lt;string&gt;
#include &lt;vector&gt;

using std::string;
using std::vector;

struct Student {
  string name;   // Eg. "John Smith"
  int number;    // Identification number, eg. "12345678"
  double grade;  // Global grade over 20.0, eg. 15.5.
};

// Sorts students by name, lexicographically: "Aab" before "Ac".
void SortStudentsByName(vector&lt;Student&gt;* students);

// Sorts students by their identification number modulo M: 0 before 1.
void SortStudentsByNumberModulo(vector&lt;Student&gt;* students, int M);

// Applies the given function to each student inside the vector.
void ApplyFunctionToStudents(std::function&lt;void(Student*)&gt; fun,
                             vector&lt;Student&gt;* students);`}</SyntaxHighlighter>
    <b>Testez</b> votre code avec cette archive <a href="td15/1.tar.gz">1.tar.gz</a>:
    <pre>tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU</b>: <samp>1.1.cc</samp>
    <br/><br/></Fragment>
  ]
};


export const TD15Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: Move</Fragment>,
  content: [
    <Fragment>
<b>Copiez</b> le corrigé du 3.4 du TD7 (<a href="td15/corrige34.h" download>3.4.h</a>, <a href="td15/corrige34.cc" download>3.4.cc</a>) dans des fichiers 2.1.h, 2.1.cc. N'<b>oubliez pas</b> d'ajuster le <samp>#include "3.4.h"</samp>.
    <br/><br/><b>Ajoutez d'abord</b> une version <samp>const</samp> de l'<samp>operator[]</samp> (qui, du coup, renvoie une <samp>const&amp;</samp>)
    <br/><b>Ajoutez ensuite</b> un <samp>operator&lt;</samp> qui fait la comparaison lexicographique:
    <br/>[1, 2, 3, 4] &lt; [1, 2, 4]
    <br/>[] &lt; [0, 0]
    <br/><samp>operator&lt;</samp>([9, 9, 9], [9, 9, 9]) renvoie <samp>false</samp> car les arguments sont égaux.
    <b>Faite tourner</b> le test avec cette archive <a href="td15/2.tar.gz">2.tar.gz</a>:
    <pre>tar xf 2.tar.gz
make 2.1</pre>
    <b className="orange">RENDU</b>: <samp>2.1.h</samp>, <samp>2.1.cc</samp>
  <br/>
  <br/>
    </Fragment>,
    <Fragment><b>Copiez</b> dans <samp>2.2.h</samp> et <samp>2.2.cc</samp> et <b>ajoutez</b> un "move" constructor et
    <samp>operator=</samp> pour que ça tourne beaucoup plus vite.
    <br/><b>Testez:</b> <samp>make 2.2</samp>
    <br/><br/><b className="orange">RENDU</b>: <samp>2.2.h</samp>, <samp>2.2.cc</samp>
  <br/>
  <br/></Fragment>,
  <Fragment>Implémentez dans un fichier <samp>2.3.h</samp>
  une <b>fonction générique</b> (<samp>template{`<>`}</samp>) <samp>Select(...)</samp> qui prend en arguments
 <ul>
   <li>un pointeur vers un tableau <samp>vector{ `<>`}</samp> d'éléments de type quelconque.
   </li>
   <li>Le <i>prédicat</i>: une fonction (ou objet fonctionnel) qui, évalué sur un élément du tableau, renvoie <samp>true</samp> ou <samp>false</samp>
   </li>
 </ul>
 cette fonction modifiera le tableau pour ne garder que les éléments vérifiant le prédicat.
 <br/>Vous ne pourrez pas utiliser <samp>std::remove_if</samp> bien sûr, puisque l'idée est de l'implémenter vous-même.
 <br/><br/><b>Indice <span className="red">important</span>:</b> au lieu de fixer le type du prédicat (vous pourriez par exemple penser à <samp>std::function{`<bool(T)>`}</samp> ou <samp>std::function{`<bool(const T&)>`}</samp>),
 utilisez un <b>type générique</b> (qui sera donc un 2<sup>ème</sup> argument de votre <samp>{`template<...>`}</samp> après le type des eléments).
 <br/>Cela permettra à l'utilisateur
 de <samp>Select(...)</samp> de l'utiliser avec des prédicats quelconques, et au passage, de passer mes tests.
 <br/><br/><b>Testez:</b>
 <pre>make 2.3</pre>
 <b className="orange">RENDU</b>: <samp>2.3.h</samp>
 <br/><br/></Fragment>
  ]
};

export const TD15Exo3 : ExoProp = {
  title: <Fragment>Exercice 3: Bit hacks</Fragment>,
  content: [
    <Fragment>
      <b>Complétez</b> le fichier <a href="td15/3.h" download>3.h</a> (également inclus ci-dessous). Pas la peine de faire un 3.cc: saisissez le code directement dans le .h.
      <SyntaxHighlighter language="cpp">{`typedef unsigned int uint32;

typedef unsigned short uint16;
typedef unsigned char uint8;

// If you want to perform some precomputation when the program starts,
// you can put it here: Init() will be called by 3.test.cc and by 3.bench.cc.
void Init() {
}

// Returns 1 if the bit #bit of x is 1, 0 otherwise.
// NOTE: bit #0 is the bit of lowest weight (i.e. x modulo 2).
inline int GetBitOf(int bit, uint32 x) {
    return -1;
}

// Set bit #bit of "x" to the given value (0 if value=false, 1 if value=true)
inline void SetBitOf(int bit, bool value, uint32* x) {
    return -1;
}

// Return x with all of its bits inversed: 1 becomes 0, 0 becomes 1.
inline uint32 NegateBits(uint32 x) {
    return -1;
}

// Return the number of bits of x that are set.
inline int BitWeight(uint32 x) {
    return -1;
}

// Return the bits of x, "flipped": bit #0 becomes bit #31,
// bit #1 becomes bit #30, and so on.
inline uint32 FlipBits(uint32 x) {
    return -1;
}`}</SyntaxHighlighter>
    <b>Indications</b>
    <ul>
      <li>Avancer progressivement: faites les fonctions une par une, <b>dans l'ordre</b>, vérifiez les avec le test, et ainsi de suite.
      </li>
      <li>Vous pourrez utiliser les opérateurs de manipulations de bits:
        <ul>
          <li><samp>x &amp; y</samp>: <em>x</em> ET <em>y</em>, bit-à-bit</li>
          <li><samp>x | y</samp>: <em>x</em> OU <em>y</em>, bit-à-bit</li>
          <li><samp>x ^ y</samp>: <em>x</em> XOR <em>y</em>, bit-à-bit</li>
          <li><samp>~x</samp>: NOT x, bit-à-bit</li>
          <li><samp>x &lt;&lt; y</samp>: Décalage des bits de x vers la gauche, de <em>y</em> bits (le résultat vaut donc <em>x</em> * 2 <sup><em>y</em></sup></li>
          <li><samp>x &gt;&gt; y</samp>: Décalage des bits de x vers la droite, de <em>y</em> bits (le résultat vaut donc <em>x</em> / 2 <sup><em>y</em></sup></li>
        </ul>
      </li>
      <li>Rappelez-vous qu'un entier <samp>unsigned int</samp>, c'est 32 bits!</li>
    </ul>
    <br/><b>Testez</b> votre code avec <a download href="td15/3.tar.gz">3.tar.gz</a>:
    <pre>rm 3.tar.gz; wget --no-cache http://fabien.viger.free.fr/cpp/td10/3.tar.gz &amp;&amp; tar xf 3.tar.gz
g++ -std=c++11 -O2 3.test.cc &amp;&amp; ./a.out</pre>
    <b className="orange">RENDU:</b> <samp>3.h</samp>
    <br/><br/>
    </Fragment>,
    <Fragment><b>(*) Améliorez</b> la performance de vos fonctions!
    <br/>Vous pouvez obtenir le temps de calcul moyen de vos fonctions avec <a href="td15/3.bench.cc" download>3.bench.cc</a>:
    <pre>g++ -std=c++11 -O2 3.bench.cc &amp;&amp; ./a.out</pre>
    <b className="red">ATTENTION!</b> Votre code devra toujours être valide (cf test ci-dessus).
    <br/><br/><b className="orange">RENDU:</b> <samp>3.h</samp> (amelioré)
    Note: sur ma machine (Core i7-5600U CPU @ 2.60GHz), j'arrive à descendre en dessous de 1ns pour chacune de ces opérations.
    <br/><br/>Note: la recherche "bit hacks" sur Google permet d'arriver sur une page sympa avec plein de recettes ésoteriques.
Ne pompez pas sans comprendre, sinon je me fâche!</Fragment>
  ]
};
