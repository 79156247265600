import React from 'react';

import { Prism } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';



interface SyntaxHighlighterProp {
    language: string
  }

export const SyntaxHighlighter : React.FunctionComponent<SyntaxHighlighterProp> = (props) =>
  <Prism language={props.language} style={dark}>
      {props.children}
  </Prism>