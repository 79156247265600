import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'

//<h2>TD 12 C++: <samp>template{`<>`}</samp></h2>
{/* <h3 className="blue">Les barêmes (en bleu) sont <i>indicatifs</i>: les notes seront certainement remontées.</h3>
Faites les exercices dans l'ordre: C'est de loin la stratégie la plus efficace. */}

// <a id="footnote1">*</a>: <i>presque</i> quelquonque: l'objet en question devra avoir un constructeur
// par copie et par défaut.
// </div>

export const TD12Exo1 : ExoProp = 
{
  title: <Fragment>Exercice 1 (20/100): Fonction générique</Fragment>,
  content: [
    <Fragment><b className="blue">(6/100)</b><br/>
    <b>Implémentez</b> dans un fichier <samp>1.1.h</samp> une fonction <i>générique</i> <samp>void Print(x)</samp> qui prend un argument <samp>x</samp>
    et l'imprime (avec <samp>cout {`<<`} x</samp>). L'argument <samp>x</samp> pourra avoir n'importe quel type.
    <br/>Notez bien que je demande un <samp>.h</samp> et pas de <samp>.cc</samp>! C'est dû à l'utilisation de <samp>template</samp>:
    dans un projet C++ classique, on implémente les fonctions génériques dans les .h, pour qu'elle soient utilisables
    par les autres fichiers (explication au tableau si vous voulez).
    <br/><br/><b>Testez</b> votre code avec cette archive <a href="1.tar.gz">1.tar.gz</a>:
    <pre>tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU</b>: <samp>1.1.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(7/100)</b><br/>
    Copiez <samp>1.1.h</samp> dans <samp>1.2.h</samp> et <i>spécialisez</i> la fonction Print
    pour qu'elle ait un comportement particulier quand x est un <samp>int</samp>: elle devra alors
    afficher <samp>"int(x)"</samp> (sans les guillemets, et en remplaçant le "x" par sa valeur).
    <br/>Vous utiliserez les <samp>template</samp>, et pas la surcharge!
    <br/><br/><b>Test</b>: <samp>make 1.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>1.2.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(7/100)</b><br/>
    Dans une copie <samp>1.3.h</samp>, continuez à spécialiser <samp>Print()</samp> pour que:
    <ul>
      <li>Si <samp>x</samp> est un <samp>unsigned int</samp>, elle affiche <samp>"unsigned int(<i className="pink">x</i>)"</samp></li>
      <li>Si <samp>x</samp> est un <samp>long</samp>, elle affiche <samp>"long(<i className="pink">x</i>)"</samp></li>
      <li>Si <samp>x</samp> est un <samp>unsigned long</samp>, elle affiche <samp>"unsigned long(<i className="pink">x</i>)"</samp></li>
    </ul>
    <br/><b>Test</b>: <samp>make 1.3</samp>
    <br/><b className="orange">RENDU</b>: <samp>1.3.h</samp>
    <br/><br/></Fragment>,

  ]
};

export const TD12Exo2 : ExoProp = 
{
  title: <Fragment>Exercice 2 (15/100): Classe générique</Fragment>,
  content: [
    <Fragment><b className="blue">(7/100)</b><br/>
    En suivant le <i>modèle</i> suivant, qui serait valide étant donné une classe donnée <samp>SomeClass</samp>,
    <b>implémentez</b> dans un fichier <samp>2.1.h</samp> une classe générique <samp>Track</samp> qui encapsulera
    une classe quelquonque<sup><a href="#footnote1">*</a></sup>.
    <br/><b className="red">Gardez</b> le même texte affiché dans <samp>cerr</samp>: les auto-tests s'en servent
    pour détécter que tout marche bien.
    <SyntaxHighlighter language="cpp">{`class Track : public SomeClass {
 public:
  Track() : SomeClass() {
    cerr << "Default-Constructing @" << this << "\n";
  }

  Track(const SomeClass& obj) : SomeClass(obj) {
    cerr << "Copy-Constructing @" << this << "\n";
  }

  ~Track() {
    cerr << "Destructing @" << this << "\n";
  }
};`}</SyntaxHighlighter>
    <b>Testez</b> votre code avec cette archive <a href="2.tar.gz">2.tar.gz</a>:
    <pre>tar xf 2.tar.gz
make 2.1</pre>
    <b className="orange">RENDU</b>: <samp>2.1.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(8/100)</b><br/>
    Copiez votre fichier dans <samp>2.2.h</samp> et ajoutez deux fonctions "accesseurs":
    <ul>
      <li><samp>Get()</samp>, qui renverra le <samp>const <i>SomeClass</i>&</samp> de l'objet encapsulé.</li>
      <li><samp>Mutable()</samp>, de même mais qui renvoie un pointeur (non-constant!) au lieu d'un <samp>const&</samp>.</li>
    </ul>
    <br/><b>Test</b>: <samp>make 2.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>2.2.h</samp>
    <br/><br/></Fragment>
  ]
};


export const TD12Exo3 : ExoProp = 
{
  title: <Fragment>Exercice 3 (15/100): Triplet{`<>`}</Fragment>,
  content: [
    <Fragment><b className="blue">(5/100)</b><br/>
    Dans un fichier <samp>3.1.h</samp>, <b>implémentez</b> une classe générique <samp>Triplet</samp>
    qui implémente un triplet d'objet quelquonques<sup><a href="#footnote1" className="footnote">*</a></sup>.
    <br/>
    Le code qui l'utilisera pourra ressembler à ça:
    <SyntaxHighlighter language="cpp">{`const double x = 3.0;
SomeClass my_class;
Triplet<int, SomeClass, double> t(42, my_class, x);
cout << "Triplet: " << t.first << "; " << t.second << "; " << t.third << "\n";
t.third /= 2.0;
cout << t.third << "\n";`}</SyntaxHighlighter>
    Cahier des charges:
    <ul>
      <li>Un <samp>Triplet</samp> de trois objets se construit en donnant les trois objets.</li>
      <li>Les <b>membres public</b> <samp>first</samp>, <samp>second</samp> et <samp>third</samp> seront
      les objets du triplet</li>
    </ul>
    <br/><b>Testez avec cette archive <a href="3.tar.gz">3.tar.gz</a>:</b>
    <pre>tar xf 3.tar.gz
make 3.1</pre>
    <b className="orange">RENDU</b>: <samp>3.1.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(3/100)</b><br/>
    Dans une copie <samp>3.2.h</samp>, ajoutez un constructeur par défaut (qui construira par défaut chaque objet du triplet).
    <br/><br/><b>Test</b>: <samp>make 3.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>3.2.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(4/100)</b><br/>
    Dans une copie <samp>3.3.h</samp>, ajoutez un operateur <samp>{`<`}</samp> qui fera la comparaison stricte dans l'ordre lexicographique:
    <ul>
      <li>{`(1, 3, 6) < (2, 2, -1)`}</li>
      <li>{`(2, 2, 2) < (2, 2, 5)`}</li>
    </ul>
    <br/><b>Test</b>: <samp>make 3.3</samp>
    <br/><b className="orange">RENDU</b>: <samp>3.3.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(3/100)</b><br/>
    Idem dans un <samp>3.4.h</samp> mais faites en sorte que votre code n'utilise que l'opérateur <samp>{`<`}</samp> sur les classes
    sous-jacentes.
    <br/><b>Test</b>: <samp>make 3.4</samp>
    <br/><b className="orange">RENDU</b>: <samp>3.4.h</samp>
    <br/><br/></Fragment>
  ]
};



export const TD12Exo4 : ExoProp = 
{
  title: <Fragment>Exercice 4 (50/100): Cardinality Counter</Fragment>,
  content: [
    <Fragment><b className="blue">(7/100)</b><br/>
    <b>Complétez</b> le fichier <a href="4.1.h" download>4.1.h</a>, en l'implémentant!
    <SyntaxHighlighter language="cpp">{`// Generic cardinality counter for hashable objects.
// It counts how many times a given object was inserted, all
// in constant time.
template<class T>
class CardinalityCounter {
 public:
  // Adds an object once, i.e. increments its cardinality count.
  void Add(const T& t) {
    // TODO
  }

  // Returns the current cardinality cound of object t, i.e. the number of
  // times Add(t) was called. Can be 0 if t was never added.
  int NumOccurences(const T& t) const {
    // TODO
  }

 private:
  // TODO
};`}</SyntaxHighlighter>
    <b>Testez</b> votre code avec cette archive <a href="4.tar.gz">4.tar.gz</a>:
    <pre>
tar xf 4.tar.gz
make 4.1</pre>
    <b className="orange">RENDU</b>: <samp>4.1.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(2/100)</b><br/>
    Dans une copie <samp>4.2.h</samp>, <b>ajoutez</b> une fonction <samp>int Size()</samp> qui
    renvoie le nombre d'élément <i>distincts</i> insérés jusque-là.
    <br/><br/><b>Test</b>: <samp>make 4.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>4.2.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(9/100)</b><br/>
    Dans une copie <samp>4.3.h</samp>, <b>ajoutez</b> une fonction <samp>const T& MostFrequent()</samp>
    qui presupposera que <samp>Size() {`>`} 0</samp> et qui renverra l'élément dont la cardinalité est
    la plus grande (si plusieurs éléments sont <i>ex aequo</i>, retournez n'importe lequel).
    <br/>Débrouillez-vous pour que ça marche en <b>temps constant O(1)</b>, les autres opérations
    devant également rester en temps constant.
    <br/><br/><b>Test</b>: <samp>make 4.3</samp>
    <br/><b className="orange">RENDU</b>: <samp>4.3.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(12/100)</b> &nbsp; <b>(*)</b><br/>
    Dans une copie <samp>4.4.h</samp>, <b>ajoutez</b> une fonction <samp>Remove()</samp> qui
    enlève <b>une</b> occurence d'un objet donné en argument. Si l'objet n'était pas présent, elle ne
    fait rien.
    <br/><b>Attention</b>:
    <ul>
      <li>Toutes les autres opérations doivent rester en temps constant</li>
      <li>
        Si l'objet n'est plus présent après la fonction Remove() [nombre d'occurences = 0],
        pensez bien à l'enlever complètement de votre structure de données! Le test le verifiera.
      </li>
      <li>
        Faites en sorte que MostFrequent() marche encore:
        <ul>
          <li>Vous pourrez par exemple effectuer un re-calcul en O(N) <i>si nécéssaire</i>, i.e. quand
          on a appelé Remove() sur l'élément le plus fréquent (car il n'est peut-être plus le plus fréquent!).
          </li>
        </ul>
      </li>
    </ul>
    <br/><b>Test</b>: <samp>make 4.4</samp>
    <br/><b className="orange">RENDU</b>: <samp>4.4.h</samp>
    <br/><br/></Fragment>,
    <Fragment><b className="blue">(20/100)</b> &nbsp; <b>(**)</b><br/>
    Modifiez la structure de données pour garantir une complexité O(log(N)) voire O(1) pour toutes les operations, dans le pire cas, même <samp>Remove()</samp>.
    <br/>Pour garantir le max de points, obtenez la complexité pire cas en O(1).
    <br/>
    <b>Indice</b>: dans un conteneur de type <a href="http://en.cppreference.com/w/cpp/container/set"><samp>set</samp></a>,
    <a href="http://en.cppreference.com/w/cpp/container/unordered_set"><samp>unordered_set</samp></a>,
    <a href="http://en.cppreference.com/w/cpp/container/map"><samp>map</samp></a> ou
    <a href="http://en.cppreference.com/w/cpp/container/unordered_map"><samp>unordered_map</samp></a>,
    les références (donc également les pointeurs) vers des éléments du set ou du map (clé et/ou valeur)
    restent valides tant qu'on n'enlève pas l'élément! En d'autres termes, une fois inséré, les clés (et/ou leur valeur associée)
    gardent une adresse mémoire constante tant qu'il ne sont pas retirés. Le code suivant marche bien, par exemple:
    <SyntaxHighlighter language="cpp">{`std::map<int, double> my_map;
my_map[5] = 4.567;
double* value_of_5 = &(my_map[5]);
int* key_of_5 = &(my_map.find(5)->first);
my_map[4] = 3;
my_map[2] = 8;
my_map.erase(4);
cout << *key_of_5;  // Prints "5".
cout << *value_of_5;  // Prints "4.567".
my_map[5] = 9.8;
cout << *key_of_5;  // Prints "5".
cout << *value_of_5;  // Prints "9.8".`}</SyntaxHighlighter>
    <b>Test</b>: <samp>make 4.5</samp>
    <br/><b className="orange">RENDU</b>: <samp>4.5.h</samp>
    <br/><br/></Fragment>
  ]
};
