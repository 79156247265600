import React from 'react';

import { AppBar, Toolbar, Container, IconButton, Typography } from '@material-ui/core';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import './styles.css'


export class UtilsPage extends React.Component<{}, {}>{
    public render() {
        return (
            <React.Fragment>

                <h3 id="Outils">Outils</h3>
                <ul>
                    <li><a href="../cours">Page du Cours</a>
                        <br /><br />
                    </li>
                    <li>Le terminal (et le shell <span>bash</span>):
                        <ul>
                            <li>Lancer le terminal: <b>Ctrl+Alt+T</b></li>
                            <li><span>pwd </span> : afficher le répertoire courant</li>
                            <li><span>ls </span> : afficher le contenu du dossier courant (<span>ls -l</span> donne les détails)</li>
                            <li><span>cd <span className="red">dossier</span> </span> : aller dans le dossier <span className="red">dossier</span> du dossier courant</li>
                            <li><span>cd .. </span> : remonter dans le dossier parent</li>
                            <li><span>cp <span className="red">fichier1</span> <span className="pink">fichier2</span> </span> : Copie le fichier <span className="red">fichier1</span> dans un nouveau fichier  <span className="pink">fichier2</span></li>
                            <li><span>rm <span className="red">fichier</span> </span> : Supprime le fichier <span className="red">fichier</span></li>
                            <li><span>gedit <span className="red">fichier.cc</span> </span> : ouvrir (ou créer) le fichier <span className="red">fichier.cc</span> avec l'éditeur de texte gedit. Ajouter une esperluette permet au terminal d'effectuer une autre tâche en parallèle (<span>gedit <span className="red">fichier.cc</span> &</span>)</li>
                            <li><span>man <span className="red">commande</span> </span> : obtenir de l'aide à propos de <span className="red">commande</span></li>
                            <li><b>Ctrl</b>+<b>C</b> : interrompre/annuler la commande en cours (bien utile quand votre programme plante)</li>
                            <li>Copier-coller dans ou depuis le terminal: utiliser <b>Ctrl+Shift+C</b> (copier) et <b>Ctrl+Shift+V</b> (coller), ou la selection de la souris (copier) et le bouton du milieu de la souris (coller).</li>
                        </ul>
                        <br />
                    </li>

                    <li>Le compilateur <span>g++</span> (depuis le terminal):
                        <ul>
                            <li><b>Compiler</b> <span>g++ -std=c++11 -Wall -Wextra -Werror -Wno-sign-compare -O2 <span className="red">mon_code.cc</span></span></li>
                            <li><b>Éxécuter</b> <span>./a.out</span>   <b>Seulement si la compilation a bien marché</b>!!!</li>
                        </ul>
                        <br />

                    </li>

                    <li>Pour ne pas taper ça a chaque fois, éxécuter cette commande (copier-coller dans le terminal):
                    <SyntaxHighlighter language="bash">{`alias g++="g++ -std=c++11 -Wall -Wextra -Werror -Wno-sign-compare -O2"`}</SyntaxHighlighter>
                    </li>
                </ul>
                <br />


                <h2>Préparation des machines personnelles</h2>
                <p>Les énoncés sont conçus pour pouvoir être réalisés sur les machines des salles de TDs de l'université et il n'est pas attendu que vous apportiez vos machines personnelles.
       Il est néanmoins possible d'apporter et d'utiliser son ordinateur personnel pour des raisons d'ordre pratique ou pour des raisons sanitaires (ceci a été rédigé pendant l'épidémie Covid19).</p>

                <p>Vous êtes encouragés à utiliser votre environnement de développement préféré si vous en avez un.
                Le script de vérification grade.sh attend cependant une certaine organisation des fichiers qui peut ne pas être compatible avec votre éditeur : certains éditeurs créent un répertoire différent par projet et n'accepte qu'un exécutable par projet.
       Vous êtes invités à rassembler tous les fichiers au sein d'une même archive en fin de séance pour le fonctionnement du script grade.sh.</p>

                <p>Le script grade.sh nécessite les outils GNU pour fonctionner correctement ; ces outils ne sont pas disponibles nativement ni sur Windows ni sur Mac.</p>

                <p>Les instructions pour <a href="/macOS">maxOS</a>, les instructions pour <a href="/windows10">Windows 10</a>.</p>
                
            </React.Fragment>
        );

    }
};
