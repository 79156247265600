import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD13Exo1 : ExoProp =
{
  title: <Fragment>Exercice 2: Graphe, BFS, Dijkstra</Fragment>,
  content: [<Fragment><b>Complétez</b> le fichier <a href="td13/2.1.h" download>2.1.h</a> et <b>implémentez-le</b> dans un fichier <samp>2.1.cc</samp>!
  <SyntaxHighlighter language="cpp">{`
// A directed graph is a set of nodes, linked by arcs. Arcs are directed: they
// go from a node to another node.
// In this implementation, each node is an integer in [0, num_nodes-1].
//
// For example, the following graph:
//
//  0 <--- 1 <--> 3 ---> 4
//  ^      |       \     ^
//  |      v        '----'
//  '----- 2
//
// Can be obtained by calling this on a fresh DirectedGraph:
//   AddArc(1, 0);
//   AddArc(1, 3);
//   AddArc(3, 1);
//   AddArc(2, 0);
//   AddArc(1, 2);
//   AddArc(3, 4);
//   AddArc(3, 4);
//
class DirectedGraph {
public:
void AddArc(int from, int to);

// Returns 1 + the highest node index that was ever given to AddArc(), as
// 'from' or 'to' argument.
int NumNodes() const;

// Returns the number of arcs originating from "node".
// In the example above, OutDegree(1) = 3, OutDegree(4) = 0.
int OutDegree(int node) const;

// Returns all the destination nodes that were added with arcs
// originating from "node".
// In the example above, Neighbors(1) is {0, 2, 3} and Neighbors(2) is {0}.
const vector<int>& Neighbors(int node) const;

private:
// TODO
};`}</SyntaxHighlighter>
 <b>Testez</b> votre code avec cette archive <a href="td13/2.tar.gz">2.tar.gz</a>:
 <pre>tar xf 2.tar.gz
make 2.1</pre>
 <b className="orange">RENDU</b>: <samp>2.1.h</samp>, <samp>2.1.cc</samp>
 <br/><br/></Fragment>,
 <Fragment>Implémentez la fonction <samp>BFS()</samp> décrite dans le fichier <a href="td13/2.2.h" download>2.2.h</a>:
 <SyntaxHighlighter language="cpp">{`#include "2.1.h"

// Runs a Breadth-First-Search on the graph, starting from node "src".
// See https://en.wikipedia.org/wiki/Breadth-first_search .
// Returns a vector of size N (N is the number of nodes of the
// graph) representing the "parent" tree: parent[i] is the parent of
// node #i in the BFS tree. The parent of "src" is itself, and the
// parent of a node that wasn't reached by the BFS exploration is -1.
vector<int> BFS(const DirectedGraph& graph, int src);`}</SyntaxHighlighter>
 La complexité devra être O(M + N), où M est le nombre d'arcs et N le nombre de noeuds.
 <br/><br/><b>Test</b>: <b><i>vous devez d'abord faire le 2.3!</i></b> 
 <br/><samp>make 2.2</samp>
 <br/><b className="orange">RENDU</b>: <samp>2.2.cc</samp>
 <br/><br/></Fragment>,
 <Fragment>Implémentez la fonction <samp>GetBfsDistances()</samp> décrite dans le fichier <a href="td13/2.3.h" download>2.3.h</a>:
 <SyntaxHighlighter language="cpp">{`#include <vector>
using std::vector;

// Extracts the distances of each node in the given BFS tree, which
// is the returned format described in 2.2.h
// Eg. in the following tree, whose root is "2":
//
//         .---- 4
//         v
//   2 <-- 3 <-- 1
//   ^
//   '---- 0 <-- 5
//
// The bfs tree is represented by the following 'parent' vector:
// [2, 3, 2, 2, 3, 0]
// And the distance vector should be:
// [1, 2, 0, 1, 2, 2]
//
// If a node was not reached by the BFS, its parent is -1, and its distance
// should also be -1.
vector<int> GetBfsDistances(const vector<int>& bfs_tree);`}</SyntaxHighlighter>
 <b>Test</b>: <samp>make 2.3</samp>
 <br/><b className="orange">RENDU</b>: <samp>2.3.cc</samp>
 <br/><br/></Fragment>,
 <Fragment>    Implémentez la fonction <samp>GetShortestPathFromRootedTree()</samp>
 décrite dans le fichier <a href="td13/2.4.h" download>2.4.h</a>:
 <SyntaxHighlighter language="cpp">{`#include <vector>
using std::vector;

// Returns the shortest path, from the source of a BFS to the given target node.
// The argument is the target node and the BFS "parent" tree.
// If the target node was not reached by the BFS, the returned path should be
// empty.
// Example: using the same example as in 2.3.h, with BFS 'parent' tree:
// [2, 3, 2, 2, 3, 0]
// Then:
// - the shortest path to node #4 should be: [2, 3, 4]
// - the shortest path to node #0 should be: [2, 0]
// - the shortest path to node #5 should be: [2, 0, 5]
// - the shortest path to node #2 should be: [2]
vector<int> GetShortestPathFromRootedTree(const vector<int>& parent, int target);`}</SyntaxHighlighter>
 <b>Test</b>: <samp>make 2.4</samp>
 <br/><b className="orange">RENDU</b>: <samp>2.4.cc</samp>
 <br/><br/></Fragment>,
 <Fragment>Copiez <samp>2.1.h</samp> et <samp>2.1.cc</samp> dans <samp>2.5.h</samp> et <samp>2.5.cc</samp>,
 et modifiez la fonction <samp>AddArc()</samp> pour qu'elle prenne un argument
 supplémentaire: <samp>double length</samp>.
 <br/>Modifiez également la fonction <samp>Neighbors()</samp> pour qu'elle renvoie
 un <samp>const vector{`<pair<int, double>>&`}</samp>.
 <br/><br/><b>Test</b>: <samp>make 2.5</samp>
 <br/><b className="orange">RENDU</b>: <samp>2.5.h</samp> et <samp>2.5.cc</samp>
 <br/><br/></Fragment>,
 <Fragment><b>(**)</b>
 Implémentez la fonction <samp>Dijkstra()</samp>
 décrite dans le fichier <a href="td13/2.6.h" download>2.6.h</a>:
 <SyntaxHighlighter language="cpp">{`#include "2.5.h"

// Runs a Dijkstra search on the graph, starting from node "src".
// See https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm .
// Returns the same "parent" vector as BFS() in 2.2.h.
vector<int> Dijkstra(const DirectedGraph& graph, int src);`}</SyntaxHighlighter>
 On utilisera <samp>priority_queue{`<>`}</samp> sur une <samp>struct</samp>
 qu'on définira, qui correspond à un noeud du graph associé à sa
 distance depuis la source, assorti d'un opérateur <samp><b>{`<`}</b></samp>
 adapté à ce qu'on en veut pour la <samp>priority_queue</samp>.
 <br/>La complexité devra être O(N + M log(M)).
 <br/><b>Test</b>: <samp>make 2.6</samp>
 <br/><b className="orange">RENDU</b>: <samp>2.6.cc</samp>
 <br/><br/></Fragment>
 
  ]
};