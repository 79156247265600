import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD6Exo1 : ExoProp = {
  title: <Fragment>Exercice 1</Fragment>,
  content: [
    <Fragment><b>Complétez</b> le fichier <a href="td6/1.1.cc" download>1.1.cc</a> avec l'implémentation des <b>constructeurs</b> de la classe <samp>Uint2048</samp> ci-dessous (télécharger: <a href="1.1.h" download>1.1.h</a>) :
    <SyntaxHighlighter language="cpp">{`using uint64 = unsigned long long;
    
class Uint2048 {
  public:
    Uint2048();
    Uint2048(int x);
    
    bool operator<(Uint2048 other);
    Uint2048 operator+(Uint2048 other);
    
    void Print();
    
  private:
    uint64 u_[32];
};`}</SyntaxHighlighter>
        <b>Vérifiez</b> avec <a href="1.tar.gz">cette archive</a> et la commande:
        <SyntaxHighlighter language="bash">{`
    tar xf 1.tar.gz
    make 1.1`}</SyntaxHighlighter>
        <b className="orange">RENDU:</b> <samp>1.1.cc</samp>
        <br/>
        <br/></Fragment>,
    <Fragment>Copiez <samp>1.1.h</samp> et <samp>1.1.cc</samp> dans
    <samp>1.2.h</samp> et <samp>1.2.cc</samp> (n'<b>oubliez pas</b> de changer le <samp>#include "1.1.h"</samp>), et <b>ajoutez</b>
    un <i>opérateur de comparaison</i> <samp><b>&lt;</b></samp>.
    <br/>Vous pourrez notamment vous inspirer de l'opérateur <samp><b>+</b></samp> déjà fourni dans la classe.
    <br/><br/><b>Test:</b> <samp>make 1.2</samp>
    <br/><br/><b className="orange">RENDUS:</b> <samp>1.2.h</samp> et <samp>1.2.cc</samp>
    <br/>
    <br/></Fragment>,
    <Fragment>Copiez <samp>1.2.h</samp> et <samp>1.2.cc</samp> dans
    <samp>1.3.h</samp> et <samp>1.3.cc</samp> et
    <b>ajoutez <i>tous</i></b> les mot-clés
    <samp>const</samp> qui sont <b>sensés</b>.
    <br/><br/><b>Test</b>: <samp>make 1.3</samp>

    <br/><br/><b>Ensuite</b>, essayez de passer des
    arguments par <i>référence</i> au lieu de <i>copie</i>. Quel est l'impact sur le temps de calcul (quand on fait <samp>make 1.3</samp>)?
    <br/><br/><b className="orange">RENDU:</b> <samp>1.3.h</samp>, <samp>1.3.cc</samp>
    <br/>
    <br/></Fragment>,
    <Fragment>On veut maintenant comptabiliser le nombre total d'additions efféctuées dans l'ensemble des classes <samp>Uint2048</samp> par notre programme (pour debugger, par exemple).
    <br/>Comment faire? Copiez <samp>1.3.h</samp> et <samp>1.3.cc</samp> dans
    <samp>1.4.h</samp> et <samp>1.4.cc</samp> et
    <b>ajoutez</b> une fonction <samp>NumAdditions</samp> qui renverra ce nombre.
    <br/><i>Indice:</i> <samp>static</samp>
    <br/><br/>Cette fonction peut-elle être <samp>const</samp>?  Pourquoi?
    <br/><br/><b>Test</b>: <samp>make 1.4</samp>
    <br/><br/><b className="orange">RENDUS:</b> <samp>1.4.h</samp>, <samp>1.4.cc</samp>
    <br/>
    <br/></Fragment>,
    <Fragment>Copiez tout dans <samp>1.5.h</samp> et <samp>1.5.cc</samp> et ajoutez et implémentez l'opérateur <samp><b>-</b></samp> (soustraction).
    <br/>Vous pourrez vous inspirer du <samp><b>+</b></samp>, mais attention c'est un peu plus compliqué.
    <br/><br/><b>Test</b>: <samp>make 1.5</samp>
    <br/><br/><b className="orange">RENDUS:</b> <samp>1.5.h</samp>, <samp>1.5.cc</samp>
    <br/><br/></Fragment>,
    <Fragment><b>(*)</b> Copiez tout dans <samp>1.6.h</samp> et <samp>1.6.cc</samp> et ajoutez et implémentez l'opérateur <samp><b>*</b></samp> (multiplication), qui devra marcher en O(2048<sup>2</sup>).
    <br/>Notamment, l'algo naïf suivant est beaucoup <b className="red">trop lent</b> pour calculer a * b. Trouvez mieux!
    <SyntaxHighlighter language="cpp">{`Uint2048 result(0);
  Uint2048 one(1);
  Uint2048 counter(0);
  while (counter &lt; a) {
    result = result + b;
    counter = counter + one;
  }
  return result;`}</SyntaxHighlighter>
  <b>Test</b>: <samp>make 1.6</samp>
  <br/><br/><b className="orange">RENDUS:</b> <samp>1.6.h</samp>, <samp>1.6.cc</samp>
  <br/><br/></Fragment>,
<Fragment><b>(***)</b> Copiez tout dans <samp>1.7.h</samp> et <samp>1.7.cc</samp> et ajoutez et implémentez l'opérateur <samp><b>/</b></samp> (division entière). En O(2048<sup>2</sup>) également.
  <br/><br/><b>Test</b>: <samp>make 1.7</samp>
  <br/><br/><b className="orange">RENDUS:</b> <samp>1.7.h</samp>, <samp>1.7.cc</samp>
  <br/><br/></Fragment>
  ]
};

export const TD6Exo2 : ExoProp = {
  title: <Fragment>Exercice 2</Fragment>,
  content: [
    <Fragment><b>Simulez</b> l'execution du code suivant dans votre tête. Quel sera l'affichage?
    <br/>
    <br/>
    <img src="td6/2.1.png" alt="Code C++ à simuler" />
    <br/><br/><b className="orange">RENDU:</b> <samp>2.1.txt</samp> qui contiendra 3 lignes, suivant le format:
    <pre>5,6,7
  8,9,10
  11,12,13</pre>en remplaçant, bien sûr, les valeurs des entiers!
    <br/>
    <br/>
    <b>Vérifiez</b> avec <a href="2.1.test.sh">ce fichier</a> et la commande:
    <pre>bash 2.1.test.sh</pre>
    <br/>
    <br/></Fragment>
  ]
};
