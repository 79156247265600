import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'

export const TD3Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Pointeurs, tableaux, algorithmes</Fragment>,
  content: [
    <Fragment><b>Écrivez</b> un fichier <span>1.4.cc</span> contenant une fonction <span>SortRev</span> qui prend en argument un tableau d'entiers de taille arbitraire, et qui renvoie une copie du tableau triée par ordre <b>décroissant</b>. Le tableau pris en entrée ne doit <b>pas</b> être modifié.
    <br/>Indications:
    <ul>
      <li>Pour passer un tableau constant (non-modifiable) à <span>SortRev</span>, on utilisera les 2 arguments <span>int num</span> (contenant la taille du tableau) et <span>const int* values</span> (le tableau proprement dit).
      </li>
      <li>Pour renvoyer un tableau, ici on se contentera de renvoyer le pointeur vers les données, de type <span>int*</span>, car la taille est connue par l'utilisateur (puisque c'est la même que l'argument <span>num</span>)
      </li>
      <li>On peut allouer des tableaux avec l'opérateur <a href="http://www.cplusplus.com/reference/new/operator%20new[]/#example"><span>new[]</span></a>
      </li>
      <li>On peut utiliser la fonction <a href="http://www.cplusplus.com/reference/algorithm/sort/"><span>sort()</span></a> disponible dans le <i>header</i> <span>&lt;algorithm&gt;</span>. Elle prend en arguments deux pointeurs: un vers le début du tableau, un vers la fin.
      </li>
    </ul>
    <br/><b>Vérifiez</b> que votre code marche avec le fichier <a href="1.4.main.cc" download><span>1.4.main.cc</span></a>:
    <SyntaxHighlighter language="bash">g++ 1.4.main.cc && ./a.out</SyntaxHighlighter>
    Essayez par exemple avec <span>1 4 3 9 -4 5 2 5</span>
    <br/><br/><b className="orange">RENDU:</b> <span>1.4.cc</span>
    <br/><br/></Fragment>,
    <Fragment><b>(*) Écrivez</b> un fichier <span>1.5.cc</span> contenant une fonction <span>HIndex</span> qui calcule le <a href="https://fr.wikipedia.org/wiki/Indice_h">h-Index</a> d'un scientifique, étant donné une liste du nombres de citations qu'il a eu pour chacun de ses articles:
    
    <SyntaxHighlighter language="cpp">
                            {`int HIndex(int num_articles, const int* num_citations) {
  // TODO
}`}</SyntaxHighlighter>
    Indication: on pourra utiliser la question précédente.
    <br/><br/><b>Vérifiez</b> votre code en copiant <span>1.4.main.cc</span> dans <span>1.5.main.cc</span>, et en le modifiant pour faire tourner <span>HIndex()</span> et afficher le résultat. Le h-Index de l'exemple donné dans le 1.4 doit être 4, par exemple.
    <br/>
    Autres cas à tester:
    <ul>
      <li>h-Index(<span>[1 2 1]</span>) = <span>1</span></li>
      <li>h-Index(<span>[]</span>) = <span>0</span></li>
      <li>h-Index(<span>[0]</span>) = <span>0</span></li>
      <li>h-Index(<span>[1 2 3 4 5 6 7 8 9]</span>) = <span>5</span></li>
      <li>h-Index(<span>[1 2 3 4 5 6 7 8 9 9]</span>) = <span>5</span></li>
      <li>h-Index(<span>[1 2 3 4 5 6 7 8 9 9 9]</span>) = <span>6</span></li>
    </ul>
    <p>via ce code par exemple :</p>
    <SyntaxHighlighter language="cpp">
{`#include <iostream>
#include "1.6.cc"

struct TestCase {
    std::vector<int> citations;
    int hindex;
};
      
const std::vector<TestCase> cases{
    {{1, 2, 1}, 1},
    {{}, 0},
    {{0}, 0},
    {{1, 2, 3, 4, 5, 6, 7, 8, 9}, 5},
    {{1, 2, 3, 4, 5, 6, 7, 8, 9, 9}, 5},
    {{1, 2, 3, 4, 5, 6, 7, 8, 9, 9, 9}, 6},
};
      
int main()
{
    int i = 0;
    for (const auto testcase : cases)
    {
        auto res = HIndex(testcase.citations.size(), testcase.citations.data());
        if (res != testcase.hindex)
            std::cout << "error for " << i << ": " << res << " vs " << testcase.hindex << std::endl;
        i++;
    }
}`}</SyntaxHighlighter>
      
    <br/><b className="orange">RENDU:</b> <span>1.5.cc</span>
    <br/><br/></Fragment>,
    <Fragment><b>(***) Écrivez</b> une version <span>1.6.cc</span> du fichier précédent qui n'utilise pas <span>sort()</span> (même indirectement) et qui fonctionne en temps <b>linéaire</b>, c'est-à-dire une
    complexité <i>O(N)</i>, où <i>N</i> est le nombre d'articles. NOTE: <span>sort()</span> fonctionne en <i>O(N log(N))</i>.
    <br/><br/><b className="orange">RENDU:</b> <span>1.6.cc</span>
    <br/><br/></Fragment>
  ]
};

export const TD3Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: matrices</Fragment>,
  content: [
    <Fragment><p>Pour cet exercice, le code suivant vous permettra de visualiser le contenu d'une matrice via un opérateur de stream :</p>
    <SyntaxHighlighter language="cpp">
    {`#include <iostream>
        
struct Mat
{
  size_t n;
  size_t m;
        
  double *content;
        
  Mat(size_t _n, size_t _m, double *_content) : n(_n), m(_m) {
    content = _content;
  }
};
        
std::ostream& operator<<(std::ostream& os, const Mat& m)
{
  for (int i = 0; i < m.n; ++i)
  {
    os << "( ";
    for (int j = 0; j < m.m; ++j)
    {
      os << m.content[i * m.m + j] << " ";
    }
    os << ")" << std::endl;
  }
  return os;
}`}</SyntaxHighlighter>
      Écrire un fichier 2.1.cc correspondant au fichier <a href="2.1.h" download>2.1.h</a> suivant:
        <SyntaxHighlighter language="cpp">{`// Computes the trace (sum of elements of the diagonal) of a square NxN matrix,
// flattened in memory.
double trace(double* matrix, int N);`}</SyntaxHighlighter>
          <b className="orange">RENDU:</b> <span>2.1.cc</span>
          <br/><br/></Fragment>,
      <Fragment>De même avec <a href="2.2.h" download>2.2.h</a>:
      <SyntaxHighlighter language="cpp">{`// Computes the matrix product of two matrices A and B of respective sizes NxM and MxP,
// and returns a newly allocated NxP matrix. All matrices are flattened.
double* matrix_prod(int n, int m, int p, double* a, double *b);`}</SyntaxHighlighter>
        <b className="orange">RENDU:</b> <span>2.2.cc</span>
        <br/><br/></Fragment>,
      <Fragment><b>(*)</b> De même avec <a href="2.3.h" download>2.3.h</a>:
      <SyntaxHighlighter language="cpp">{`// Computes the transpose of a NxM matrix (see wikipedia), in-place. Stores
// the result in the source matrix.
void transpose(int n, int m, double* matrix);`}</SyntaxHighlighter>
        <p>Vous pourrez utiliser le code suivant pour visualiser ce qui se passe :</p>
        <SyntaxHighlighter language="cpp">
    {`#include "2.3.cc"
#include <iostream>
    
int main()
{
  {
    // Easy case: square matrix
    double A[16] = {0.1, -0.2, 0.3, -0.4,
                    0.5, -0.6, 0.7, -0.8,
                    0.9, -1.0, 1.1, -1.2,
                    1.3, -1.4, 1.5, -1.6};
    Mat matA(4, 4, A);
    transpose(4, 4, A);
    std::cout << matA << std::endl;
    transpose(4, 4, A);
    std::cout << matA << std::endl;
  }
    
  {
    // Rectangular matrix.
    double A[15] = {0.1, -0.2, 0.3, -0.4, 0.5,
                    -0.6, 0.7, -0.8, 0.9, -1.0,
                    1.1, -1.2, 1.3, -1.4, 1.5};
    
    Mat matA(3, 5, A);
    std::cout << matA << std::endl;
    transpose(3, 5, A);
    Mat matAt(5, 3, A);
    std::cout << matAt << std::endl;
    
    transpose(5, 3, A);
    std::cout << matA << std::endl;
  }
}`}</SyntaxHighlighter>
        <b className="orange">RENDU:</b> <span>2.3.cc</span>
        <br/><br/></Fragment>,
      <Fragment><b>(****) Améliorer</b> votre 2.3.cc pour qu'il n'utilise <b>aucune mémoire supplémentaire</b> (et qu'il marche meme sur des matrices non carrées).
      <br/><br/><b className="orange">RENDU:</b> <span>2.3.cc</span>, amelioré.
      <br/><br/></Fragment>
  ]
};