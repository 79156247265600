import React from 'react';
import './App.css';
import { MainView } from './main-view';
import { CssBaseline, ThemeProvider, createMuiTheme, useMediaQuery } from '@material-ui/core';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      },
    })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={{ textAlign: "left" }}>
        <MainView />
      </div>
    </ThemeProvider>
  );
}

export default App;
