import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD16Exo0 : ExoProp = {
  title: <Fragment>Exercice 0: QCM (révisions)</Fragment>,
  content: [
    <Fragment>Faites ce QCM: <a href="http://goo.gl/forms/MtzwBmXsI2">http://goo.gl/forms/MtzwBmXsI2</a>.
    <br/>Vous avez <b>60 minutes</b>.
    
    Il sera noté au cours de la séance, mais seulement à titre indicatif: la note ne comptera pas.
    <p>
    Voir les <b><a href="https://docs.google.com/spreadsheets/d/1lhEPF0DGbYO6_6esKMIcG8GHe2-3Cp0wpWge7nQ8h4s/edit?usp=sharing">Résultats 2018</a></b> du QCM
    </p></Fragment>
  ]
};


export const TD16Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Programmation (révisions)</Fragment>,
  content: [
    <Fragment>
<b>Écrire</b> dans un fichier <samp>1.1.cc</samp> une fonction <samp>RevString</samp>
    qui inverse les caractères d'une chaîne de caractères, eg. <samp>"hello"</samp> &rarr; <samp>"olleh"</samp>.
    La signature de la fonction devra être exactement:
    <pre><code className="c++">void RevString(string* s);</code></pre>
    Vous devrez listez tous les <samp>#include</samp> et <samp>using</samp> nécessaires.

    <br/><br/>
    <b>Testez</b> votre code avec cette archive <a href="td16/1.tar.gz">1.tar.gz</a>:
    <pre>tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU:</b> <samp>1.1.cc</samp>
    <br/><br/>
    </Fragment>,
    <Fragment>
    <b>Écrire</b> dans un fichier <samp>1.2.h</samp> une fonction générique GetSecond() qui prend
    un <samp>const vector{ `<T>&`}</samp> en argument, contenant un type quelconque <samp>T</samp>,
    et renvoie l'<b>index</b> du 2<sup>ème</sup> plus grand élément (le type de retour est donc <samp>int</samp>).
    <br/><br/><b>Exemple</b>: <samp>GetSecond({`{3, 5, 2, 4, 0, 6}`})</samp> doit renvoyer <samp>1</samp> (l'index de <samp>5</samp>).
    <br/><br/>
    La fonction devra fonctionner en <b>O(N)</b>, où N est la taille du vecteur.
    Vous pourrez présupposer que le vecteur donné en entrée contient au moins 2 éléments.
    <br/><br/>Pour tester: <samp>make 1.2</samp>
    <br/><br/><b className="orange">RENDU:</b> <samp>1.2.h</samp>
    <br/><br/>
    </Fragment>,
    <Fragment>
    <b>Écrire</b> dans un fichier <samp>1.3.h</samp> une fonction générique GetKth() qui prend
    un <samp>{ `const vector<T>&`}</samp> en argument, et un entier <samp>K</samp>,
    et renvoie le <samp>K</samp>-ième plus <b>grand</b> élément du vecteur
    (indexé à 0: pour le plus grand élement on utiliserait K=0, pour le 2<sup>ème</sup> plus grand on utiliserait K=1).
    <br/>La syntaxe d'un appel de cette fonction serait par exemple:
    <SyntaxHighlighter language="cpp">{`vector<string> x = {"Hello", "Beautiful", "World"};
int idx = GetKth(x, 1);  // We expect idx = 0.`}</SyntaxHighlighter>
    La fonction doit fonctionner en <b>O(N.log(K))</b>, où N est la taille du vecteur. Ou mieux.
    <br/><br/>Pour tester: <samp>make 1.3</samp>  Note: Le test de performance devrait tourner en a peu près 1s.
    <br/><br/><b className="orange">RENDU:</b> <samp>1.3.h</samp>
    <br/><br/>
    </Fragment>
  ]
};