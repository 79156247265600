import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD11Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Fonctions abstraites (dans le TD10, mais à faire si vous n'aviez pas terminé)</Fragment>,
  content: [
    <Fragment>Téléchargez <a href="1.1.h" download>1.1.h</a> (copié ci-dessous) et <b>complétez</b>-le,
    et <b>implémentez</b> les classes dans un fichier <samp>1.1.cc</samp>.
    <SyntaxHighlighter language="cpp">{`class Sequence {
 public:
  // The value of the sequence at the current step.
  double Value();

  // Advances to the next step.
  void Next();
};

// 1, 1, 2, 3, 5, 8, ...
class FibonacciSequence : public Sequence {
};

// 1, 1, 2, 6, 24, 120, 720, ... modulo x: if x = 10, that's
// 1, 1, 2, 6, 4, 0, 0, ...
class FactorialModXSequence : public Sequence {
 public:
  explicit FactorialModXSequence(int x);
};`}</SyntaxHighlighter>
    Veillez à rendre les fonctions de la classe de base <samp>Sequence</samp>
    <b>abstraites</b>.
    <br/><br/><b>Testez</b> votre code avec
    <a href="1.tar.gz" download>1.tar.gz</a>:
    <pre>
tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU</b>: <samp>1.1.h</samp> et <samp>1.1.cc</samp>
    <br/><br/></Fragment>,
    <Fragment>Copiez <samp>1.1.h</samp> et <samp>1.1.cc</samp> dans <samp>1.2.h</samp> et <samp>1.2.cc</samp>.
    <br/>Ensuite, <b>Créez</b> une classe <samp>ShiftSequence</samp>, sous-classe de <samp>Sequence</samp>,
    dont le constructeur prendra
    en argument une <samp>Sequence*</samp> arbitraire <samp>seq</samp> et un décalage (entier positif)
    <samp>shift</samp>, telle que:
    <br/><i>ShiftSequence(seq, shift)<sub>n</sub></i> =
    <i>seq<sub>seq<sub>0</sub> + n + shift</sub></i>, où <i>seq<sub>0</sub></i>
    est l'étape initiale de <i>seq</i> quand on a construit l'objet
    <samp>ShiftSequence</samp>.
    <br/><br/>Le passage de <samp>seq</samp> se fera par pointeur, et la classe
    <samp>ShiftSequence</samp> <b>prendra possession</b> de l'objet <samp>seq</samp>
    donné: c'est lui qui devra se charger de sa suppression.
    <br/><br/><b>Subtilité supplémentaire</b>: contrairement au reste des <samp>Sequence</samp>
    donnés jusqu'à présent, vos objets <samp>ShiftSequence</samp> ne peuvent pas
    être copiés "naturellement" sans créer des problèmes.
    Empêchez la copie d'un <samp>ShiftSequence</samp> vers un autre! Ma solution
    préférée est la <a
       href="http://ariya.ofilabs.com/2015/01/c-class-and-preventing-object-copy.html">3<sup>ème</sup>
       méthode citée ici</a>.
    <br/><br/><b>Testez</b> votre code avec <pre>make 1.2</pre>
    <b className="orange">RENDU</b>: <samp>1.2.h</samp> et <samp>1.2.cc</samp>
    <br/><br/></Fragment>,
    <Fragment>Copiez <samp>1.2.h</samp> et <samp>1.2.cc</samp> dans <samp>1.3.h</samp> et <samp>1.3.cc</samp>.
    <br/>Ensuite, ajoutez une fonction <samp>int Step()</samp> qui renvoie l'étape courante d'une
    <samp>Sequence</samp> (elle renverra <samp>0</samp> au début).
    Pour ne pas avoir à la redéfinir pour chaque sous-classe de
    <samp>Sequence</samp>, on va utiliser une astuce:
    <ul>
      <li>Renommez tous vos <samp>Next()</samp> en <samp>InternalNext()</samp>.</li>
      <li>Implémentez <samp>Next()</samp> dans la classe de base <samp>Séquence</samp>, en
        utilisant <samp>InternalNext</samp> et un compteur local.</li>
      <li>Selon votre implémentation de <samp>ShiftSequence</samp>, vous aurez
        peut-etre besoin de modifier le compteur local lors de la construction,
        afin qu'il vale bien 0. Ça ne devrait pas vous poser de problème.</li>
    </ul>
    <br/><b>Testez</b> votre code avec <pre>make 1.3</pre>
    <b className="orange">RENDU</b>: <samp>1.3.h</samp> et <samp>1.3.cc</samp></Fragment>
  ]
};

export const TD11Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: Exceptions</Fragment>,
  content: [
    <Fragment>Dans un fichier <samp>2.1.cc</samp>, <b>implémentez</b> la fonction <samp>ParseInt()</samp> décrite
    dans <a href="2.1.h" download>2.1.h</a> (également copié ci-dessous),
    et faites-lui renvoyer les exceptions dédiées, afin que cela fonctionne
    (avec l'archive <a href="2.tar.gz" download>2.tar.gz</a>):
    <pre>
tar xf 2.tar.gz
make 2.1</pre>
    <b>Ne changez pas</b> <samp>2.1.h</samp>!
    <SyntaxHighlighter language="cpp">{`#include &lt;exception&gt;

// Converts a string to an integer, with strict checking: throws an exception
// if 'str' doesn't represent an integer in the range [-2^31...2^31-1].
//
// Exceptions thrown:
// - NullPtrException: if str == nullptr.
// - EmptyStrException: if str is empty.
// - BadFormatException: if the string doesn't follow the regexp 0|(-?[1-9][0-9]*)
// - OverflowException: if the string follows the regexp above, but the integer
//   is outside p[-2^31...2^31-1].
//
// The last two exceptions take an argument at construction: a string that helps
// to know what failed. Typically it would be the input that's being parsed.
// The first two don't need it, because the context is pretty clear (nullptr
// or empty string).
int ParseInt(const char* str);

using std::exception;
class NullPtrException : public exception {};
class EmptyStrException : public exception {};
class BadFormatException : public exception {
 public:
  explicit BadFormatException(const char* data) : data_(data) {}
  // See http://www.cplusplus.com/reference/exception/exception/what/
  const char* what() const throw() override { return data_; }
 private:
  const char* data_;
};
class OverflowException : public exception {
 public:
  explicit OverflowException(const char* data) : data_(data) {}
  const char* what() const throw() override { return data_; }
 private:
  const char* data_;
};`}</SyntaxHighlighter>
    <b className="orange">RENDU</b>: <samp>2.1.cc</samp></Fragment>,
    <Fragment></Fragment>
  ]
};

export const TD11Exo3 : ExoProp = {
  title: <Fragment>Exercice 3: Fonction générique (<i>aka</i> <samp>template</samp>)</Fragment>,
  content: [
    <Fragment><b>Implémentez</b> dans un fichier <samp>3.1.h</samp> une fonction <i>générique</i> <samp>void Print(x)</samp> qui prend un argument <samp>x</samp>
    et l'imprime (avec <samp>cout &lt;&lt; x</samp>). L'argument <samp>x</samp> pourra avoir n'importe quel type.
    <br/>Notez bien que je demande un <samp>.h</samp> et pas de <samp>.cc</samp>! C'est dû à l'utilisation de <samp>template</samp>:
    dans un projet C++ classique, on implémente les fonctions génériques dans les .h, pour qu'elle soient utilisables
    par les autres fichiers.
    <br/><br/><b>Testez</b> votre code avec <a href="3.tar.gz" >3.tar.gz</a>:
    <pre>
tar xf 3.tar.gz
make 3.1</pre>
    <b className="orange">RENDU</b>: <samp>3.1.h</samp>
    <br/><br/></Fragment>,
    <Fragment>Copiez <samp>3.1.h</samp> dans <samp>3.2.h</samp> et <i>spécialisez</i> la fonction Print
    pour qu'elle ait un comportement particulier quand x est un <samp>int</samp>: elle devra alors
    afficher <samp>"int(x)"</samp> (sans les guillemets, et en remplaçant le "x" par sa valeur).
    <br/>Vous utiliserez les <samp>template</samp>, et pas la surcharge!
    <br/><br/><b>Test</b>: <samp>make 3.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>3.2.h</samp>
    <br/><br/></Fragment>,
    <Fragment>Dans une copie <samp>3.3.h</samp>, continuez à spécialiser <samp>Print()</samp> pour que:
    <ul>
      <li>Si <samp>x</samp> est un <samp>unsigned int</samp>, elle affiche <samp>"unsigned int(<i className="pink">x</i>)"</samp></li>
      <li>Si <samp>x</samp> est un <samp>long</samp>, elle affiche <samp>"long(<i className="pink">x</i>)"</samp></li>
      <li>Si <samp>x</samp> est un <samp>unsigned long</samp>, elle affiche <samp>"unsigned long(<i className="pink">x</i>)"</samp></li>
    </ul>
    <br/><b>Test</b>: <samp>make 3.3</samp>
    <br/><b className="orange">RENDU</b>: <samp>3.3.h</samp>
    <br/><br/></Fragment>
  ]
};