import React from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';
import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'



export const Exo1 : ExoProp = 
  {
    title: <React.Fragment>Exercice 1: Premier programme. Boucle <samp>for</samp></React.Fragment>,
    content: [
  (<React.Fragment>Ouvrir un fichier <samp>1.cc</samp> et coller le contenu suivant:
    <SyntaxHighlighter language="cpp">
              {`#include <iostream>
  using namespace std;

  int main() {
    cout << "Hello" << endl;
    int result = 1;
    for (int i = 1; i <= 10; i++) {
      result *= i;
    }
    cout << "Result: " << result << endl;`}
            </SyntaxHighlighter></React.Fragment>),
  (<React.Fragment>Faites-le tourner! cf instructions ci-dessus.
        <br />
        Avant de passer à la suite, vous devez <b>tout</b> comprendre de cet exemple (je vous expliquerai au besoin).</React.Fragment>)]
  };


export const Exo2  : ExoProp = {
  title: (<React.Fragment>Exercice 2: Erreurs de compilation et d'éxecution</React.Fragment>),
  content: [
  (<React.Fragment>
    Voilà un programme avec quelques erreurs. Copiez-le dans un fichier <samp>2.cc</samp>:
<SyntaxHighlighter language="cpp">{`#include <iostream>
#include <cmath>
using namespace std

int main() {
cout << "Hello" << endl;
double sum;
int N = 100000000;
for (int i = 1; i &lt; n; i+++) {
sum = sum + 1 / i;
}
ccout << "Harmonic sum: " << sum << endl;
cout << "Log(N):       " << log(N) << endl;
}`}</SyntaxHighlighter>
Et tentez de le compiler:
<SyntaxHighlighter language="bash">g++ 2.cc</SyntaxHighlighter>
Vous devriez voir pas mal d'erreurs de compilation.
<br /><br />
          <b>Réparez</b> <i>toutes</i> les erreurs de compilation en modifiant <samp>2.cc</samp> avec votre
editeur de texte, et en relançant <samp>g++ 2.cc</samp>, jusqu'à ce que ça marche.
<ul>
            <li>Votre éditeur de texte doit montrer les numéros de ligne, sinon, demandez-moi comment les ajouter!</li>
            <li>Le plus facile est de garder les 2 fenêtres (editeur et terminal) visibles en même temps</li>
          </ul>
          <br /></React.Fragment>),
  (<React.Fragment>Le résultat n'est pas celui escompté. Pourquoi? Indice: <i>division entière</i>.
<br /><b>Réparez</b> le programme pour qu'il calcule bien la somme de la série harmonique de 1 à
9999999
<br /><br /></React.Fragment>),
  (<React.Fragment>
Modifiez votre programme pour qu'il affiche la valeur (approchée) de la constante d'Euler, sur une quatrième ligne, après un caractère <samp>':'</samp>.
<br />Voir la page wikipedia sur la <a href="https://fr.wikipedia.org/wiki/S%C3%A9rie_harmonique#Termes_suivants_du_d.C3.A9veloppement_asymptotique">Série Harmonique</a>.
<br /><br /></React.Fragment>),
  (<React.Fragment>
Modifiez votre programme pour qu'il calcule le coefficient du prochain terme dans le dévelopement limité (utilisez la valeur de la constante d'Euler donnée sur Wikipedia), et l'affiche sur une cinquième ligne après un caractère <samp>':'</samp>
<br/><br/><b className="orange">RENDU:</b> <samp>2.cc</samp></React.Fragment>)
  ]
};


export const Exo3 = {
title: (<React.Fragment>Exercice 3: <samp>cin</samp>; Primalité</React.Fragment>),
content: [
  (<React.Fragment>Sauvez ce programme dans <samp>3.1.cc</samp> et faites-le tourner:
    <SyntaxHighlighter language="cpp" >{`#include <iostream>
using namespace std;
    
int main() {
    int x;
    cout << "Entrez un nombre et appuyez sur [Enter]: ";
    cin >> x;
    cout << "Ce nombre est ";
    if (x % 2 == 0) {
      cout << "pair";
    } else {
      cout << "impair";
    }
    cout << endl;
}`}</SyntaxHighlighter></React.Fragment>),
  (<React.Fragment><b>Copiez</b> le code dans un autre fichier <samp>3.2.cc</samp> et <b>Modifiez</b>-le pour qu'il demande 2 entiers <samp>a</samp> et <samp>b</samp> à l'utilisateur (l'un après l'autre), et qu'il teste la <i>divisibilité</i> de <samp>a</samp> par <samp>b</samp>. Il devra afficher "est divisible" si b divise a, et "n'est pas divisible" sinon.
  <br />
      <br /><b className="orange">RENDU:</b> <samp>3.2.cc</samp>
      <br /><br /></React.Fragment>),
  (<React.Fragment><b>Copiez</b> le code dans un autre fichier <samp>3.3.cc</samp> et <b>Modifiez</b>-le avec une boucle <samp>for</samp> pour qu'il teste la <i>primalité</i> d'un nombre <samp>n</samp> donné par l'utilisateur (i.e. le nombre <samp>n</samp> est-il premier?). Il devra afficher "premier" si le nombre est premier, et "composite" si le nombre n'est pas premier.
  <br />Indications:
  <ul>
        <li>Créez une variable booléenne avant votre boucle: <samp>bool is_prime = true;</samp>. Cette variable peut se faire affecter d'autres valeurs plus tard dans le programme (<samp>is_prime = false</samp> par exemple), et on peut l'utiliser dans un test: <samp>{`if (is_prime) { ... }`}</samp>.</li>
        <li>[Facultatif] L'instruction <samp>break</samp> interrompt une boucle <samp>for</samp>.</li>
      </ul>
      <br /><b className="orange">RENDU:</b> <samp>3.3.cc</samp>
      <br /><br /></React.Fragment>),
  (<React.Fragment>Faites tourner votre programme pour n = 2 milliards + 11: <samp>2000000011</samp>.
    <br /><b>Optimisez</b> votre programme pour qu'il tourne instantanément.
    <br /><b>Vérifiez-le</b> sur: 9, 11, 14, 25, 2000000011, 2000000017. Lesquel(s) sont premiers?
    <br /><br /></React.Fragment>),
  (<React.Fragment>Utilisez le type <samp>long long</samp> au lieu de <samp>int</samp> pour utiliser des entiers 64 bits, qui vont jusqu'à 8 milliards de milliards (en gros). Essayer votre programme sur <samp>1000000000000000001</samp> et sur <samp>1000000000000000003</samp>.
  <br /><b className="orange">RENDU:</b> toujours <samp>3.3.cc</samp>, amélioré.
  <br /><br /></React.Fragment>),
  (<React.Fragment>Modifiez-le pour afficher la décomposition en facteurs premiers du nombre (un facteur premier par ligne, dans l'ordre croissant).
  <br />Essayez-le sur <samp>6</samp>, <samp>7</samp>, <samp>60</samp>, <samp>81</samp> et <samp>152415790094497781</samp>.
  <br /><b className="orange">RENDU:</b> <samp>3.6.cc</samp>.
  <br /><br /></React.Fragment>)
]
}; 
