import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'



export const TD14Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: file I/O</Fragment>,
  content: [
    <Fragment><b>Écrivez</b> un programme 1.1.cc (avec un <samp>main()</samp>) qui compte le nombre de lignes
    d'un fichier texte, dont le nom sera donné en <b>argument</b> du programme. Il affichera ce nombre dans <samp>stdout</samp>.
    <br/>Indices: <samp>ifstream</samp> et <samp>getline</samp> pour lire un fichier (cherchez sur cppreference.com),
    <samp>int main(int argc, char** argv)</samp> pour récupérer les arguments du programme.
    <br/><br/><b>Testez</b> votre code cette archive <a href="1.tar.gz">1.tar.gz</a>:
    <pre>tar xf 1.tar.gz
make 1.1</pre>
    <b className="orange">RENDU</b>: <samp>1.1.cc</samp>
    <br/><br/></Fragment>,
    <Fragment>De même, <b>écrivez</b> un programme 1.2.cc qui <b>affiche</b> (dans stdout) les <i>N</i>
    premières lignes d'un fichier texte. Le programme prendra le nom du fichier en 1<sup>er</sup> argument
    et le nombre <i>N</i> en 2<sup>ème</sup> argument. 
    <br/><br/><b>Test</b>: <samp>make 1.2</samp>
    <br/><b className="orange">RENDU</b>: <samp>1.2.cc</samp>
    <br/><br/></Fragment>,
    <Fragment>De même, <b>écrivez</b> un programme 1.3.cc qui <b>réduit</b> un fichier donné à ses <i>N</i>
    dernières lignes.
    <br/><b>Exemple</b> pour N=3: un fichier contenant:
    <SyntaxHighlighter language="none">{`Hello
World
This is a
simple

text file`}</SyntaxHighlighter>
    <br/>ne contiendra, après ce programme, plus que:
    {/* <table style="border:1px solid black;"><tr><td><samp>simple<br/>
<br/>
text file</samp></td></tr></table> */}
    <br/><b className="red">Attention!</b> votre code ne devra pas prendre plus de mémoire que le contenu des N dernières lignes!
    <br/>Le test le verifiera.<br/>
    <br/><b>Test</b>: <samp>make 1.3</samp>
    <br/><b className="orange">RENDU</b>: <samp>1.3.cc</samp>
    <br/><br/></Fragment>
  ]
};


export const TD14Exo2 : ExoProp = {
  title: <Fragment>Exercice 2: Smart pointers</Fragment>,
  content: [
    <Fragment><b>Implémentez</b> <samp>ComputeMedianObj()</samp> qui est déclaré dans
    <a href="2.1.h" download>2.1.h</a> (copié ci-dessous) dans un fichier
    <samp>2.1.cc</samp>, <b className="red">sans utiliser le mot-clé</b> <samp>delete</samp>.
    <br/>Ne modifiez <b>pas</b> <samp>2.1.h</samp> et n'implémentez pas
    les constructeurs/destructeurs de MyObj.
    <SyntaxHighlighter language="cpp">{`class MyObj {
 public:
  explicit MyObj(int seed);  // Implemented in 2.1.test.cc
  ~MyObj();                  // Implemented in 2.1.test.cc

  // NOT default- and copy-constructible!
  MyObj() = delete;
  MyObj(const MyObj&amp;) = delete;

  int Value() const { return value_; }
 private:
  const int value_;
};

// This function, which you must implement, should do the following:
// - Construct N instances of MyObj, with seed=0...N-1.
// - Sort them by Value()
// - Return the "median" value, i.e. the Value() of the N/2-th MyObj in the
//   sorted list.
// - [IMPORTANT!] Destroy all instances of MyObj before returning.
int ComputeMedianMyObj(int N);`}</SyntaxHighlighter>
    <b>Testez</b> votre code avec <a href="2.tar.gz">2.tar.gz</a>:
    <pre>tar xf 2.tar.gz
make 2.1</pre>
    <b className="orange">RENDU</b>: <samp>2.1.cc</samp>
    <br/><br/></Fragment>
  ]
};

export const TD14Exo3 : ExoProp = {
  title: <Fragment>Exercice 3: Factory</Fragment>,
  content: [
    <Fragment><b>Complétez</b> <a href="3.1.h" download>3.1.h</a> (version initiale copiée ci-dessous)
    et écrivez l'implémentation dans un fichier <samp>3.1.cc</samp>.
    <SyntaxHighlighter language="cpp">{`class Hat {
 public:
  Hat() {  ++num_hats_global_; }
  ~Hat() { --num_hats_global_; }
  static int NumHatsGlobal() { return num_hats_global_; }
 private:
  static int num_hats_global_;
};

class HatFactory {
 public:
  // The factory constructs a new hat, and gives me a pointer to the
  // newly constructed object. The caller does not own the Hat.
  Hat* NewHat();

  // All hats ever constructed are destructed when the factory is destructed.
  ~HatFactory();

 private:
  // TODO
};`}</SyntaxHighlighter>
    <b>Testez</b> votre code avec cette archive <a href="3.tar.gz">3.tar.gz</a>:
    <pre>tar xf 3.tar.gz
make 3.1</pre>
    <b className="orange">RENDU</b>: <samp>3.1.h</samp> et <samp>3.1.cc</samp>
    <br/><br/></Fragment>
  ]
};
