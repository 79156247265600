import React, { Fragment } from 'react';


import './pages/styles.css'


export const Rendu : React.FunctionComponent<{file: string}> = props => {
    return (<Fragment><b className="orange">RENDU:</b> <samp>{props.file}</samp><br/><br/></Fragment>)
}

