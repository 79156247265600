import React, { Fragment } from 'react';

import './utils'
import './styles.css'
import { SyntaxHighlighter } from '../syntaxhighlighter'
import {ExoProp} from './tdX'


export const TD4Exo1 : ExoProp = {
  title: <Fragment>Exercice 1: Classes, mini-projet</Fragment>,
  content: [
    <Fragment>Dans un fichier <span>student.h</span>, <b>écrivez</b> le prototype (i.e. <i>déclaration</i>) d'une classe <span>Student</span> contenant:
    <ul>
      <li>Le nom de l'étudiant (limité à 127 caractères: <b>on n'utilisera pas <span>string</span></b>)</li>
      <li>La date de naissance de l'étudiant, encodée dans un <b>entier</b> au format YYYYMMDD.</li>
    </ul>
    Le <i>constructeur</i> de cette classe devra prendre ces données en argument.
    <br/>
    <br/><b>Ajoutez</b> à cette classe les fonctions (<b>seulement les déclarations!</b>):
    <ul>
      <li>Name(), qui renvoie le nom (un <span>const char*</span>).</li>
      <li>Birthday(), qui renvoie un entier representant l'anniversaire de l'étudiant au format MMDD (donc s'il est né le 23/07, on renvoie 723)</li>
    </ul>
    <br/><b className="orange">RENDU:</b> <span>student.h</span>
    <br/>
    <br/></Fragment>,
    <Fragment>Dans un fichier <span>student.cc</span>, <b>écrivez</b> l'implémentation de cette classe.
    <br/>Indications:
    <ul>
      <li>N'oubliez pas d'inclure le <span>.h</span> au début.</li>
      <li>La syntaxe pour définir une fonction de classe hors de la classe ressemble à:
      <SyntaxHighlighter language="cpp">{`type Class::Method(type_arg arg1, type_arg arg2) {
  ...
}`}</SyntaxHighlighter>
      </li>
      <li><b>Copiez</b> bien la chaine de caractères (le nom), caractère par caractère. Ne gardez pas que le pointeur!
      <br/>On pourra par exemple utiliser <a href="http://www.cplusplus.com/reference/cstring/strncpy"><span>strncpy</span></a> mais attention à bien ajouter le caractère <span>'\0'</span> si nécessaire!</li>
    </ul>
    <br/>
    <b>Vérifiez</b> en compilant vos fichiers: <span>g++ student.cc -c -o student.o</span>. Réparez <span>student.h</span> si besoin.
    <br/><br/><b className="orange">RENDU:</b> <span>student.cc</span>
    <br/>
    <br/></Fragment>,
    <Fragment>Dans 2 fichiers <span>class.h</span> et <span>class.cc</span>, <b>écrivez</b> le prototype et l'implémentation (i.e. <i>déclaration</i> et <i>définition</i>) d'une classe <span>Class</span> contenant:
    <ul>
      <li>Le nom du cours (taille illimitée), par exemple "XML", et une fonction <span>Name()</span> le renvoyant.
      <br/>Mais cette fois, <b>utilisez <span>string</span></b> au lieu de <span>char*</span> ou <span>char[]</span>! vous aurez besoin de <span>#include &lt;string&gt;</span> et de <span>using std::string;</span>.</li>
      <li>Le nombre maximal d'étudiants pouvant s'inscrire à ce cours (qui ne dépassera jamais 1000), et une fonction <span>MaxNumberOfStudents()</span> le renvoyant.</li>
      <li>La liste des étudiants inscrits a ce cours:
        <ul>
          <li>Le nombre d'étudiants inscrits: une fonction <span>NumStudents()</span>.</li>
          <li>La possibilité d'inscrire un étudiant au cours: une fonction <span>AddStudent(Student* student)</span>, qui renvoie l'index de l'étudiant (0 pour le premier étudiant, 1 pour le deuxième, etc), ou -1 si le cours est déjà rempli au maximum.</li>
          <li>Consulter un étudiant inscrit: une fonction <span>GetStudent(int student_index)</span>. Que va-t'elle renvoyer?</li>
        </ul>
      </li>
    </ul>
    <br/><b>Note</b>: Pensez à inclure le fichier <span>student.h</span>
    <br/><b>Note</b>: Pour construire une <span>Class</span>, on prendra son nom et son nombre maximal d'étudiants, et on aura aucun étudiant inscrit au début.
    <br/>
    <br/>Pensez à <b>Vérifier</b> votre code, au moins en le compilant!
    <br/><br/><b className="orange">RENDUS:</b> <span>class.h</span> et  <span>class.cc</span>
    <br/>
    <br/></Fragment>,
    <Fragment>Dans des fichiers <span>io.h</span> et <span>io.cc</span>, <b>écrivez</b> le prototype puis l'implémentation des fonctions:
    <ul>
      <li><span>void PrintStudent(const Student&amp;)</span>, qui "affiche" la description d'un étudiant (avec <span>cout &lt;&lt; ...</span>) sur une ligne.</li>
      <li><span>void PrintClass(const Class&amp;)</span> qui affiche la description d'une classe (sur une ligne) et de tous les étudiants qui y sont inscrits (un par ligne)</li>
      <li><span>Student* EnterStudent()</span> qui <i>crée</i> et <i>remplit</i> un <span>Student</span> (nom, date de naissance) avec l'entrée interactive <span>cin</span>, et en utilisant l'opérateur <a href="http://en.cppreference.com/w/cpp/memory/new/operator_new"><span>new</span></a></li>
      <li><span>Class* EnterClass()</span> qui fait la même chose pour une classe, puis qui demande le nombre d'étudiants a inscrire, et qui les saisit un par un et les ajoute.</li>
    </ul>
    <br/>
    Incluez bien les fichiers <span>student.h</span> et <span>class.h</span> dans le fichier <span>io.h</span>.
    <br/>Cela provoquera sans doute une erreur de compilation de "déclaration dupliquée": la solution, classique en C++, est un <span>#ifdef</span> guard dans les fichiers <span>.h</span>. Je vous expliquerai au tableau. 
    <br/><br/><b className="orange">RENDUS:</b> <span>io.h</span> et <span>io.cc</span>
    <br/>
    <br/></Fragment>,
    <Fragment>Dans un fichier <span>main.cc</span>, <b>écrivez</b> une fonction <span>main()</span> qui, à l'aide des fonctions faites plus haut, demandera l'entrée interactive d'une <span>Class</span>, et l'affichera ensuite.
    <br/>
    <br/><b>Compilez</b>-le par étapes séparées:
    <ul>
      <li>Pour chaque <i>module</i> fait précédemment: <span>g++ -c <span className="red">module</span>.cc -o <span className="red">module</span>.o</span></li>
      <li>Pour le main.cc, au choix:
        <ul>
          <li><span>g++ module1.o module2.o .. main.cc [-o main]</span></li>
          <li>Idem, mais avec l'option <span>-c</span>, et avec <span>-o main.o</span>, et ensuite <span>g++ module1.o module2.o .. main.o [-o main]</span></li>
        </ul>
      </li>
    </ul>
    <br/><b>Faites-le tourner!</b>
    <br/><br/><b className="orange">RENDU:</b> <span>main.cc</span>
    <br/>
    <br/></Fragment>,
    <Fragment>(*) Écrivez un <span>Makefile</span> qui orchestrera tout ça, avec une <i>target</i> par défaut qui sera le binaire <span>main</span>, et une <i>target</i> <span><span className="red">module</span>.o</span> par module.
    <br/>
    <br/><b>Testez-le</b> avec la commande <span>make</span>.
    <br/><br/><b className="orange">RENDU:</b> <span>Makefile</span>
    <br/>
    <br/></Fragment>
  ]
};

